import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    changeTopBg:0 || localStorage.getItem('changeTopBg'),
  },
  getters: {
  },
  mutations: {
    changeTopBgFunc(state,newState) {
      state.changeTopBg = newState
      localStorage.setItem('changeTopBg',state.changeTopBg)
    }
  },
  actions: {
  },
  modules: {
  }
})
