module.exports = {
    /****菜单****/
    header: {
        name:'中文',
        Home: 'Home',
       /****走进多科****/
        EnteringDooKoo:'Entering Dookoo',
        企业介绍:'Introduction',
        资质证书:'Company Qualifications',
        新闻中心:'News Center',
        社会责任:'Social Responsibility',
        /****产品中心****/
        ProductCenter:'Product Center',
        ///食品
        食品装备:'Food Equipment',
        智能包装:'Intelligent Packaging',
        异物检测:'X-ray Inspection System',
        金属检测:'Metal Detector',
        重量检测:'Checkweigher',
        密封检测:'Sealing Detection',
        机器人码垛:'Robot Palletizing',
        ///药品
        药品装备:'Pharmaceutical Equipment',
        立式超声波洗瓶机:'Vertical Ultrasonic Bottle Washing Machine',
        热风循环隧道烘箱:'Hot Air Circulation Tunnel Oven',
        罐装加塞机:'Canning And Stoppering Machine',
        轧盖机:'Capping Machine',
        在线灯检机:'Online Light Inspection Machine',
       /****解决方案****/
        Solution:'Solution',
        智慧工厂建设与运营:'Smart Factory Construction And Operation',
        智能自动化装备全链路服务:'Intelligent Automation Total Solution',
        注射剂无菌灌装联动线:'Injection Aseptic Filling Linkage Line',
        /****数智工厂****/
        DigitalIntelligenceFactory:'Digital Intelligence Factory',
        设备物联网平台:'Device IoT Platform',
        制造运营管理:'MES',
        高级计划排程:'APS',
        仓储管理系统:'WMS',
        能源管理:'EMS',
        设备数字运维:'Equipment Operation And Maintenance',
        设备效率助手:'Device Efficiency Assistant',
        设备健康:'PHM',
        企业资产管理:'EAM',
        生产场景解决方案:'Production Scenario Solutions',
        智慧园区场景解决方案:'Smart Park Scenario Solution',
        数字化服务运营场景:'Digital Service Operation Scenarios',
        /****协同创新****/
        CollaborativeInnovation:'Collaborative Innovation',
        客制化研发中心:'Customized R&D Center',
        产学研合作:'Industry University Research Cooperation',
        高质量发展:'High Quality Development',
    },
    /****首页****/
    // tab
    dktablist: {
        智能装备:'Intelligent Equipment',
        tit1:'Intelligent Packaging',
        cont1:'Dookoo efficient, stable, beautiful, environmentally friendly packaging new technologies, widely satisfied the packaging of various contents, whether it is sauce, seasoning, filling, porridge, soy milk and other fluid foods, or particles, blocks, strips, shapes,, shapes, strips, shapes, strips, strips, and Powder solid food can be according to actual production needs.',
        tit2:'X-ray Inspection System',
        cont2:'The product has different X-ray absorption rates through different materials, reflecting different grayscale brightness images on the X-ray image. Foreign objects (stones, glass, metal, etc.), missing, damaged, irregular, material counting, edge sealing, cracks, etc. in the materials are detected.',
        tit3:'Metal Detector',
        cont3:'Used for the detection and removal of metal foreign objects in non-metallic materials. Powder pipeline type free falling metal detection is suitable for the problem of foreign object mixing in different production periods, providing rich detection product equipment.',
        解决方案:'Solution',
        tit4:'Intelligent factory construction and operation',
        cont4:'Dookoo provide business consulting for medium and high -end food and drug customers, planning and design, construction, engineering construction, equipment integration, Internet of Things empowerment, operation management, technical support and maintenance, core components and other services. We are closer to the market demand of high -speed changes and create value for customers.',
        tit5:'Intelligent automation equipment full link service',
        cont5:'Dookoo can provide customers with intelligent packaging, measuring systems, leveling systems, Metal Detector，X-ray Inspection System, Checkweigher, sealing detection, integrated machine for opening and sealing boxes, stacking systems, and other integrated solutions for post packaging automation and intelligence.',
        tit6:'Injection aseptic filling linkage line',
        cont6:'Dookoo injection sterile -filled linked line products include: fully automatic vertical ultrasonic bottle washing machine, high -efficiency hot air circulating sterilized tunnel -type oven, advanced filling plug machines, and stable and controllable covering machines.',
        物联网:'IOT',
        tit7:'Intelligent manufacturing',
        cont7:'Long -term surrounding intelligent manufacturing systems, virtual simulation to build the core technology of the enterprise, and is committed to exploring the intelligent manufacturing system.',
        tit8:'Smart energy',
        cont8:'Intelligent maintenance accurate analysis, improve energy efficiency from multiple angles, optimize the performance of equipment in its life cycle, reduce costs and reduce carbon footprints.',
        tit9:'Smart asset',
        cont9:'The knowledge that is transformed into market value is all the knowledge and skills that a company can generate profits. The value of smart capital is where human wisdom lies.'
    },
    // 走进多科
    enterdk:{
        走进多科:'Entering Dookoo',
        p1:'Dookoo, founded in 2008, is a leading food smart factory service provider in China. Including: business consulting, overall plant planning and design, engineering construction, equipment integration, IoT empowerment, operation management, technical services and support, etc.',
        p2:"5 subsidiaries under Dookoo. We have established two research and development centers in Shanghai (Shanghai Hongqiao Green Valley Talent Port) and Changshu, and collaborated with well-known universities such as the Chinese Academy of Engineering, Jiangsu University, and Wuhan University. With a core of doctoral and master's degrees, we have a research and development team of over 50 people covering 8 majors including mechanical, electrical automation, electronics, software engineering, algorithms, image recognition, big data, and artificial intelligence. Currently, we have obtained over 100 patent honors; It is a key national research and development plan of Wuhan University during the 13th Five Year Plan: an intelligent equipment base for key technologies in food safety big data; Jiangsu University's 13th Five Year National Key R&D Plan: Chinese style automated central kitchen complete equipment research and demonstration intelligent equipment base.",
        p3:'Dookoo has two major production bases in Changshu and a nationwide office. The first base is a modern chemical plant covering an area of 20000 square meters. In 2022, with an investment of 320 million yuan, a new phase II production base with a total area of 64000 square meters will be built. After completion, it is expected to produce 4100 industrial automatic control system devices annually.',
        p4:'We closely monitor social, government, and global trends, and work together with customers and suppliers to create an innovative environment conducive to sustainable development, helping the entire industry achieve common transformation and create a better future together.',
    },
    // 历史历程
    history:{
        y1:'Deeply cultivate the food and medicine industry',
        y2:'Patents&Honors',
        y3:'Sales country and region',
        y4:'Serving customers',
        num1:15,
        num2:120,
        num3:70,
        num4:8000,
        years:'years',
    },
     // 合作客户
     custom:{
        custom:'Custom',
        
    },
    // 资质荣誉
    honer:{
        honer:'Qualification&Honor',
    },
     // 新闻中心
     center:{
        center:'News Center',
        tit1:'Dookoo Participation|Munich IBA2023',
        cont1:'The Munich International Baking Technology Expo (IBA) opened grandly on October 22nd! On October 22-26,2023,global baking professionals gathered at the Munich Exhibition Center in Germany to discuss baking and pastry making techniques.',
        tit2:"National prosperity and people's peace,family reunion",
        cont2:"Warmly celebrate the 74th anniversary of the founding of the People's Republic of China!",
        tit3:"Warmly celebrate the 74th anniversary of the founding of the People's Republic of China!",
        tit4:'Autumn has arrived, what is food? What are the delicious mooncakes in stock??',
        tit5:'Dookoo and Jiaxing Future Food Research Institute establish a joint laboratory',
        tit6:"Good News | Shanghai Weixiangduo Kitchen Technology Co. Ltd. Established",
        tit7:"Exhibition Trailer | ProPak China 2023",
        tit8:'Dookoo Exhibition | CIPM2023 (Autumn)',
        cont3:'The 63rd (Autumn 2023) National Pharmaceutical Machinery Expo and 2023 (Autumn) China International Machinery and Pharmaceutical Expo will be held in Xiamen International Expo Center from November 13 to 15, 2023, Dookoo injection aseptic filling linkage line overall solution and filling machine, Lamp inspection machine, packaging machine, X-ray foreign body detection machine and other stand-alone equipment appeared at booth 1-70.',
    },

     /****底部导航，联系我们****/
     contact:{
        联系我们: 'Contact Us',
        总部地址: 'Headquarters Address: Room 618, Building G, Hongqiao Green Valley Plaza, Minhang District, Shanghai',
        生产基地: 'Production Base: No.1 Duofu Road, Yushan High tech Zone, Changshu City, Jiangsu Province',
        生产基地2:'No.25 Dongshan Road, Changshu City, Jiangsu Province',
        电话:    'Phone:+86-4008009801',
        传真:    'Fax:86）021-50802583',
        邮箱:    'Email: dookoo_nl@126.com',
        公众号:   'Official Account', 
        商务咨询:'Consulting',
        姓名:'Name',
        手机号:'Phone',
        咨询留言:'Consultation Message',
        提交:'Submit',
        公司:'Shanghai Dookoo Electronic Technology Co.,Ltd',
        版权所有:'Copyright',
        备案号:'Filing Number',
    },

     /****走进多科****/
     enterDk:{
        topTitle:'Intelligent Equipment For Future Life',
        公司资质:'Company Qualifications',
        专利证书:'Patent',
        社会责任:'Social Responsibility',
         // 社会责任
         title1:'Dookoo is not only a business, but also a social enterprise',
         title2:'Dookoo actively promotes business based social and environmental values',
         tit1:'Guarding Security',
        cont1:"Consumer safety: Dookoo safeguards the food safety of 48 industries and billions of consumers. Help customers identify key hazard control points: Dookoo assistance to customers in different industries to identify and break through key hazard control points in their production process. The product meets GMP standards: minimizing the use of external tools and reducing food safety risks in packaging.",
        tit2:'Environmentally Friendly',
        cont2:"Technology Assists Environmental Upgrading: Make technology an environmentally friendly driver and promote the widespread application of more environmentally friendly food packaging materials. Dookoo's packaging equipment is suitable for more environmentally friendly PE films. Compared to composite films, each packaging material saves approximately 38% of raw materials and 50% of energy consumption.",
        tit3:'Intelligent Manufacturing Future',
        cont3:'Collaborative innovation: Starting from insight, respecting competence, and working with customers to create more intelligent and efficient solutions. More than one-third of the multidisciplinary products are deeply co created and created by industry leaders, reducing the social research and development costs caused by supply and demand mismatch. Hard core research and development: Dookoo bravely challenges itself, constantly explores new possibilities, and improves industry standards with technical strength.',
        tit4:'Collaborative Good Governance',
        cont4:"We promise to keep our promises to our 'partners': we promise that employee salaries and supplier procurement payments will never be overdue, and we will strictly comply with contract requirements and make timely payments. Anti discrimination and gender equality: Through training mechanisms, we enhance women's work abilities and create space and opportunities for their career development. We strictly abide by ourselves, operate in compliance with regulations, and adhere to ethical and legal standards.",
        tit5:'Employee Care',
        cont5:'We always care for the health of our employees and provide opportunities for innovation in our profession. Since the establishment of Dookoo, 0100% of production line employees have received safety training and purchased insurance for major safety accidents. Human machine experience: We care about the safety experience, easy operation, and efficiency of machine users.',
    },


    /****产品中心****/
    product:{
        topTitle:'Starting from the details, only to create excellent and outstanding products',
        tit1:'Intelligent packaging',
        cont1:'Dookoo efficient, stable, beautiful and environmentally friendly packaging new technology can widely meet the packaging of various contents. Whether it is liquid food such as soy sauce, seasoning, stuffing, Congee, soybean milk, or solid food such as granules, lumps, strips, and powders, it can easily achieve "tailoring" by using a single machine or integrated production line according to actual production needs. Continuously tending towards diversity, refinement, and sustainability to meet diverse packaging needs.',
        tit2:' X-ray inspection system',
        cont2:'The product has different X-ray absorption rates through different materials, reflecting different grayscale brightness images on the X-ray image. Foreign objects (stones, glass, metal, ceramics, etc.), missing, damaged, irregular, material counting, edge sealing, cracks, etc. in the materials are detected, and different removal methods are selected to remove unqualified materials.We are committed to making food production and products safer, always pursuing the ultimate quality and guarantee: only providing the safest and best.',
        tit3:'Metal Detector',
        cont3:'Used for the detection and removal of metal foreign objects in non-metallic materials. Metal Detector is applicable to the mixing of foreign objects in different production processes, providing rich detection equipment for products. It can effectively detect foreign objects before or after processing and packaging, thereby greatly protecting product quality. The Dookoo metal detection machine adopts advanced phase tracking function and multi frequency switching function, with high detection accuracy and wide detection range.',
        tit4:' Checkweigher',
        cont4:'Realize dynamic weighing of materials with high speed, precision, and stability. Leading high weighing speed and precision, capable of achieving dynamic weighing up to 100 meters/min and a minimum detection accuracy of ± 0.15g. Overall modular design; The tabletop is stable and reliable, meeting the requirements of stable weighing, hygiene, and safety design; Unique belt anti deviation technology; Powerful software functions and algorithmic advantages; It can store hundreds of thousands of formulas and continuously store production data records for more than 5 years.',
        tit5:'Sealing detection',
        cont5:'Realize dynamic weighing of materials with high speed, precision, and stability. Leading high weighing speed and precision, capable of achieving dynamic weighing up to 100 meters/min and a minimum detection accuracy of ± 0.15g. Overall modular design; The tabletop is stable and reliable, meeting the requirements of stable weighing, hygiene, and safety design; Unique belt anti deviation technology; Powerful software functions and algorithmic advantages; It can store hundreds of thousands of formulas and continuously store production data records for more than 5 years.',
        tit6:'Robot palletizing',
        cont6:'The robot stacker is used for online stacking of cardboard boxes, integrating functional modules such as automatic pallet entry and exit, logistics transportation and positioning, robot grasping, and safety barriers. Meet the diverse needs of customers for palletizing cardboard boxes. At the same time, a multi-station stacking system can be equipped according to actual needs. By using suction cups to adsorb and stack cardboard boxes, negative pressure detection is provided to avoid missing packaging. Safety gratings are installed around the stacking area to ensure the safety of operators during the production process. The robot brand adopts front-line brands such as AB/FANUC (customer can specify), and is equipped with disassembly function and in/out stack caching stations, making manual operations more flexible.',
        tit7:'Vertical ultrasonic bottle washing machine',
        cont7:'Used for cleaning bottles before the production of freeze-dried powder injection, powder injection, water injection, and oral liquid. Consisting of a bottle inlet system, a cleaning (ultrasonic) system, a control system, a bottle outlet system, and other systems, the bottle clamping robot cleans the bottle during the process of gripping and flipping the bottle. After cleaning, the bottle returns to an upright state and is output to the next process, automatically completing the cleaning process from bottle inlet, ultrasonic cleaning, external washing, internal washing to bottle outlet. The main transmission adopts servo drive, which is compatible with a large range of bottle type replacement robots; On the HMI of the cleaning process, one click formula management is used to complete the cleaning of packaging materials according to internationally and domestically validated cleaning processes, meeting the requirements for cleaning effectiveness verification of packaging materials internationally and domestically.',
        tit8:'Hot air circulation tunnel oven',
        cont8:'Modular structure hot air circulation tunnel oven, divided into three process control areas: preheating zone, high-temperature sterilization zone, and cooling zone; The preheating zone consists of a supply fan, a dehumidification fan, a manually adjustable damper, a medium efficiency filter, and a high-efficiency filter. The high-temperature sterilization area consists of a high-temperature resistant circulating fan, electric heater, and high-temperature high-efficiency filter, forming a hot air circulation system. High temperature hot air flows through an efficient filter for filtration, heating, drying, and depyrogenation of the bottle; Excellent sterilization and depyrogenation curve, a validation system that meets international requirements; The cooling zone consists of a cooling system consisting of a supply fan, high-efficiency filter, surface cooler, and exhaust fan; The cooling area has wind pressure balance and SIP functions.',
        tit9:'Canning and stoppering machine',
        cont9:'Applied to the research and development, small-scale testing, Chinese style, and large-scale production of pharmaceutical companies, the filling speeds are 50 bottles/minute, 100 bottles/minute, 200 bottles/minute, 400 bottles/minute, 500 bottles/minute, etc. According to the characteristics of the liquid, double pump systems, peristaltic pumps, plunger pumps, time pressure methods, etc. are used for filling, and isolation protection methods include RABS, cRABS, ISOLATOR. According to process control requirements, top inlet and side inlet methods are used. Adopting a unique filling precision full process control design, it meets the requirements of residual liquid control before filling and tail liquid control before production ends.',
        tit10:'Capping machin',
        cont10:'Different specifications, different speeds, and matching methods are managed to achieve upstream and downstream connections. The aluminum cap transfer design is arranged according to the requirements of "sterile capping" and "clean capping". The capping machine is equipped with an aluminum cap self-cleaning temporary storage platform, and the air inlet method of the isolation protection system can be designed according to the workshop. Equipped with an aluminum chip collection system, rubber plug height, and uncovered identification function.',
        tit11:'Lamp inspection machin',
        cont11:'The integration of the electrical cabinet and the machine reduces the risk of communication failure by reducing cables. The lifting cam and camera are inverted, which does not occupy desktop space. The height of bottles of different specifications can be automatically adjusted by formula. The transmission adopts large modulus, helical gears, and nylon gears for low noise and more stability. The camera adopts a worm gear and worm structure for round-trip tracking with a pendulum wheel, which is more robust in structure. Bottle running has internal circulation, which can cause a large amount of bottle running for a long time. There are many testing items, such as capping quality, defect detection of freeze-dried products, the presence or damage of rubber stoppers, whether there is powder on the rubber stoppers, cracks, scratches on the neck and bottle body, and pollutants inside and outside the penicillin bottle. The key components are made of international and domestic high-quality brands, with precise and stable testing parameters, and materials that meet GMP requirements. They are resistant to cleaning, easy to clean, and have no blind spots for cleaning. ',
        查看详情:'detail',
        立即联系:'Contact',
         //产品中心 系列详情页
         智能包装系列:'Product Center - Intelligent Packaging Series',
         异物检测系列:'Product Center - X-ray Inspection System',
        金属检测系列:'Product Center - Metal Detector',
        重量检测系列:'Product Center - Checkweigher',
        密封检测系列:'Product Center - Sealing Detection',
        机器人码垛机系列:'Product Center - Robot Stacker',
        药品装备系列:'Product Center - Pharmaceutical Equipment',
        //产品中心 立即联系弹框
        取消:'cancel',
        contact:"If you would like to further understand Dookoo's products and services, please submit the following information and we will contact you immediately:",
        姓名:'Name',
        手机号:'Phone',
        公司名称:'CorporateName',
        所属行业:'Industry',
        留言:'If you have any other questions you would like to know, please feel free to leave us a message',
          // 输入框提示
          姓名提示:'Please enter your name',
          手机号提示:'Please enter your phone number',
          确认信息:'Are you sure you want to submit the information you entered?',
        // 提示框
        提示:'Prompt',
        提示内容:'Please enter your name and phone number!',
        提交中:'Submitting...',

    },

    // 智能包装
    znbz:{
        智能包装:'Intelligent packaging',
        title1:'Efficient, stable, beautiful, and environmentally friendly packaging technology',
        title2:'Dookoo keenly discovered that packaging needs are constantly becoming diverse, refined, and sustainable, and used this as a starting point for upgrading packaging technology and innovating packaging solutions to meet diverse packaging needs',
        tit1:'Satisfy differentiated products such as solids, liquids, and particles',
        cont1:'It can widely meet the packaging of various contents. Whether it is liquid food such as soy sauce, seasoning, stuffing, Congee, soybean milk, or solid food such as granules, lumps, strips, and powders, it can easily achieve "tailored clothing" by using a single machine or integrated production line according to your actual production needs.',
        tit2:'High universality and complete functionality',
        cont2:'Suitable for different industries, such as baking, refrigerated and frozen food, chocolate and candy, medicine and health, and dry food, adopting automated solutions to meet the actual requirements of production lines at different production speeds.',
        tit3:'Both safe and beautiful product packaging',
        cont3:'Compatible with a variety of materials and bag types, including pillow bags, vertical bags, box bags, improve sealing stability and sealing at the same time, enhance the beauty of bag making and sealing, efficiently create more attractive product packaging.',
        tit4:'Ensure the stability and consistency of packaging quality',
        cont4:'Intelligent packaging equipment integrates sensing, communication, artificial intelligence and other technologies, one-click operation to achieve automatic bag-making, feeding, filling and measurement, sealing and cutting, inflation, printing, finished product output, counting and other operations, reduce the uncertainty brought by manual operation, to ensure the packaging quality is consistent.',
        tit5:'Accurate control and reduced production costs',
        cont5:'During the high-speed packaging process, precise film control can not only effectively reduce the use of packaging materials and reduce production costs, but also improve the packaging quality of insurance products, reduce raw material consumption and scrap rates.',
        tit6:'Non standard customization can meet personalized manufacturing needs',
        cont6:'Starting from the actual needs of customers, combined with professional production line space planning, optimized design of production lines, and suggestions for production manpower allocation, personalized packaging and testing overall solutions can be integrated to assist in the upgrading of intelligent manufacturing in the food industry.',
        // 智能包装机产品功能
     tit7:'Intelligent Packaging Machine Money Leopard - Product Features',
     znls1:'This equipment is specially designed for the development of large-sized PE film packaging, with sustainable circulation.',
     znls2:'Mainly aimed at packaging various materials in the frozen food industry, such as frozen meatballs, steamed buns, French fries, chicken nuggets, chicken wings, chicken legs, etc.',
     znls3:'It can achieve the automatic filling and sealing function of solid bagged products. Product compatibility for packaging 0.5KG to 10KG.',
     znls4:'Full servo drive system, achieving high-speed packaging for large packaging. Used in conjunction with multi head combination scales, measuring cups, screw scales, and other systems.',
     // 智能包装机产品优势
     tit8:'Intelligent Packaging Machine Qianqian Leopard - Product Advantages',
     znls5:'High precision: Adopting a bilateral vacuum adsorption traction system, with sufficient traction power to avoid the risk of slipping during the film delivery process; Fast and stable operation, improving packaging accuracy. The photoelectric switch automatically tracks color points and accurately cuts them off; Real time detection and tracking of film edge, dynamic correction.',
     znls6:'High speed: Adopting pulse welding sealing technology, the packaging efficiency is high, up to 70 packages per minute. The membrane roll replacement is simple and fast, and can be completed in 2-3 minutes.',
     znls7:'More forward-looking: circular sustainability, early deployment of corporate strategies,; PE film material, with advantages in raw material cost; Narrow sealing, saving packaging materials, and reducing costs.',
    // 液体包装机产品功能
    tit9:'Liquid/Sauce Packaging Machine - Product Functions',
    ytls1:'This packaging machine is used to achieve automatic bag making, filling, sealing, coding and other functions for fluid bagged products.',
    ytls2:'Compatible with PA+PE composite film at 8-12 microns, achieving a packaging coverage range of 500-5000g.',
    ytls3:'Catering sauce; Seasoning; Jam; Meat filling, vegetable filling, lotus seed paste, shrimp paste, fish paste, bean paste, winter seed paste and other filling materials; And Chinese catering liquid materials such as Congee and soybean milk.',
    ytls4:'Equipped with metering systems such as screw pumps and plunger pumps, it can achieve precise quantitative packaging of various products.',
     // 液体包装机产品优势
     tit10:'Liquid/Sauce Packaging Machine - Product Advantages',
     ytls5:'Photoelectric switch automatically tracks color points, real-time detection and tracking of film edges, and dynamic correction.',
     ytls6:'Rich bag expandability, with various bag shaped modules such as vertical back seal, rectangle, and bevel selected according to requirements.',
     ytls7:'Intelligent temperature control, combined with material cleaning and extrusion function, prevents air from mixing with materials, ensures the aesthetics and stability of the sealing, and extends the product shelf life.',
     ytls8:'Touch screen control, capable of storing multiple product packaging parameters. Equipped with multiple alarm functions, it can be checked and processed according to prompts, making maintenance convenient.',
     ytls9:'Replace corresponding accessories to achieve multiple packaging specifications and flexibly respond to production.',
      // 小料包装机-产品功能
      tit11:'Small Material Packaging Machine - Product Functions',
      xlls1:'Mainly used for small material packaging of particles, liquids, and powders in food, daily chemicals, pharmaceuticals, etc., such as seasoning bags, oil bags, bagged coffee, granules and drugs.',
      xlls2:'This machine can achieve three or four edge sealing of materials. It has a set of longitudinal sealing hot roller devices, a set of transverse sealing hot roller devices, and a set of transverse sealing cold roller devices. The sealing adopts a roller method, and the vertical sealing pattern adopts a checkerboard pattern combined with a leak proof line, while the horizontal sealing pattern adopts a checkerboard pattern combined with a leak proof line or a flat sealing pattern combined with a leak proof line.',
      xlls3:'The machine is also equipped with an easy to tear cutting device for easy bag opening.',
     // 小料包装机-产品优势
     tit12:'Small Material Packaging Machine - Product Advantages',
     xlls4:'The length of the bag can be digitally set on the touch screen, making it easy and convenient to adjust the bag length.',
     xlls5:'The intelligent photoelectric color code positioning control system has the function of automatic positioning of the first bag, which can automatically eliminate the influence of interference signals caused by abnormal color code patterns and poor printing of packaging materials, and operates stably.',
     xlls6:'Realize online automatic adjustment of cutting and oblique cutting and automatic adjustment of cold sealing, solving the problems of traditional packaging machines requiring multiple shutdowns for adjustment and waste of packaging materials. Improved work efficiency and aesthetic packaging.',
     xlls7:'The variable frequency motor drives the longitudinal sealing mechanism, achieving stepless adjustment of packaging speed within the rated range.',
     //开装封箱一体机-产品功能
     tit13:'Integrated Machine For Opening, Packaging, And Sealing - Product Functions',
     kxls1:'Realize the automatic stacking, unpacking, boxing, and sealing functions of bagged products.',
     kxls2:'The packaging speed is more efficient, achieving a stacking requirement of up to 60 bags/minute.',
     kxls3:'The packaging range is wide, and it can achieve product compatibility with 400mm * 300mm * 200mm -600mm * 450mm * 350mm (non-standard customization according to requirements) cardboard boxes.',
     kxls4:'The overall production capacity can reach a maximum of 12 boxes/minute (depending on the packaging format).',
     kxls5:'It can automatically switch product specifications with one click. The overall stainless steel material has a simple design, simple cleaning, and convenient maintenance.',
      //开装封箱一体机-产品分类
      tit14:'Integrated Machine For Opening, Packaging, And Sealing - Product Classification',
      kxls6:'Opening and sealing integrated machine - Product classification drop type container machine: The fully automatic drop type container machine effectively moves the entire row of materials onto the top of the cardboard box. According to the packaging requirements, the automatic whole row is ensured to fall into the cardboard box through a positioning device.',
      kxls7:'Grab type container machine: The fully automatic grab type container machine automatically shapes and arranges packaging materials (PET bottles, glass bottles, paper cans, cans, etc.), and in conjunction with the automatic box opening machine, the grabbed materials are loaded into the opened cardboard boxes.',
      kxls8:'Side push packing machine: The side push packing machine arranges and combines products in layers, groups, and combinations. After completing the first layer of stacking, the stacker descends to the next layer of product stacking. After the product is stacked in three layers, the stacker rises to the position to be packed.',
      // 包装机类型
      type1:'720T large vertical fully automatic packaging machine',
      type2:'420T fully automatic edge ironing packaging machine',
      type3:'430 vertical packaging machine',
      type4:'Multi head combination scale',
      type5:'Bag packaging machine',
      type6:'Bag packaging machine',
      type7:'Small material packaging machine',
      type8:'110 Small Material Packaging Machine',
      type9:'129 Small Material Packaging Machine',
    },
    // 异物检测
    ywjc: {
        异物检测:'X-ray Inspection System',
        title1:'Product Value Of Dookoo X-ray Inspection System',
        tit1:'Diversified foreign object detection',
        cont1:'We are committed to making food production and products safer, always pursuing the ultimate quality and guarantee: only providing the safest and best. This is the principle standard for all our actions, and it also drives us to make all decisions, overcome difficulties, and move forward bravely.',
        tit2:'Clean up all foreign objects in bottled and canned containers in one go',
        cont2:'It is also suitable for various canned foods, cans and beverages, glass bottled canned food and beverages, and glass pharmaceutical preparations. No matter whether the foreign object is at the bottom of the container or between the contents, there is no fear of worry.',
        tit3:'Suitable for various packaging materials',
        cont3:'We provide a wide range of product testing for various conventional packaging materials, including plastic packaging bags, paper boxes/cartons, aluminum foil bags, gunny bags, fluid bags, plastic trays, PET bottles and blisters, as well as product sealing.',
        tit4:'Maintain the perfect presentation of the product',
        cont4:'While ensuring that there is no foreign object pollution inside the product, maintaining the integrity of the product packaging, testing and removal technology can comprehensively ensure the effectiveness of production, reduce resource pollution and waste caused by foreign object detection in production.',
        // 优异之处
        title2:'Advantages Of Dookoo X-ray Inspection System',
        tit5:'Source guarantee',
        cont5:'Suitable for various processes, quality control starts from the source.',
        tit6:'As small as 0.2mm diameter',
        cont6:'High sensitivity, nowhere to hide, more precise and detailed.',
        tit7:'Multiple intelligent algorithms',
        cont7:'The independently developed signal processing and image extraction technology is more stable and reliable.',
        tit8:'Investment return',
        cont8:'Minimal product waste and maximum risk prevention.',
        tit9:'Ten year quality assurance',
        cont9:'Scientific design equipped with high-quality accessories, with an average service life of over 10 years.',
        tit10:'IoT empowerment',
        cont10:'Easier digital management',
         // X-ray异物检测机 通用40系列
         title3:'X-ray Inspection System universal 40 series',
         cont11:'Product testing scope: not limited to product type, packaging form, packaging material, including plastic packaging, aluminum foil packaging, glass cans, metal cans, bulk products, etc.',
         cont12:'Foreign object detection range: It can detect metals and high-density non-metals mixed into the product, such as ceramics, glass, stones, bones, hard rubber, hard plastic, etc. The specific situation needs to be determined based on the tested product.',
         // X-ray异物检测机 通用60系列
         title4:'X-ray Inspection System universal 60 series',
         cont13:'Product testing scope: not limited to product type, packaging form, packaging material, including plastic packaging, aluminum foil packaging, glass cans, metal cans, bulk products, etc.',
         cont14:'Foreign object detection range: It can detect metals and high-density non-metals mixed into the product, such as ceramics, glass, stones, bones, hard rubber, hard plastic, etc. The specific situation needs to be determined based on the tested product.',
         // X-ray异物检测机 散料多通道系列
         title5:'X-ray Inspection System bulk material multi-channel series',
         cont15:'Detection scope: For the detection of foreign objects in bulk materials such as peanuts, nuts, and beans before packaging, and the optional removal function is equipped to remove foreign objects mixed in the materials. The multi-channel foreign X-ray Inspection System removes objects in different channels, with a smaller removal rate.',
         // 视觉检测
         title6:'Visual inspection',
         cont16:'Dookoo visual detection supports anomaly detection, such as damage, anomaly, and oversized or undersized features. Support surface foreign object detection, such as black spots, hair strands, stains, etc. Support functions such as missing, incorrect, and blurry detection of spray codes.',
         // 测照检测系列
         title7:'Lighting detection series',
         cont17:'This product can detect impurities such as glass residue, sand, and metal mixed in glass bottle sealed cans/tin sealed canned products. Through dual perspective detection, it ensures that there are no blind spots in the filled products.',
    },

    // 金属检测
    jsjc:{
        金属检测:'Metal Detector',
        //产品价值
        title1:'Value Of Dookoo Metal Detector Products',
        tit1:'Daily protection of hidden dangers of metal foreign objects',
        cont1:'In addition to product quality issues caused by metal foreign objects mixed in raw materials, it can also effectively reduce foreign object pollution caused by accidental dropping of personal items, maintenance and debugging on or near the processing line, or equipment failures.',
        tit2:'Raw materials, semi-finished products, and finished products can all be tested',
        cont2:'Suitable for the problem of foreign objects mixing in different production periods, providing rich detection equipment for products, timely and effective detection of foreign objects before or after processing and packaging, thereby greatly protecting product quality.',
        tit3:'Long term reduction of energy consumption and waste',
        cont3:'Online high-speed inspection of packaging products improves production efficiency and product quality assurance, while providing energy-saving and adaptable solutions to reduce energy losses caused by manufacturing operations such as product properties and production characteristics.',
        tit4:'Ensure consistency in testing quality',
        cont4:'Maximize quality assurance, reduce product recall risks, protect brand image, and reduce consumer impact.',
        tit5:'Ten year quality assurance',
        cont5:'Scientific design equipped with high-quality accessories, with an average service life of over 10 years.',
        // 优异之处
        title2:'Advantages Of  Dookoo Metal Detector',
        tit6:'Advanced phase tracking and multi frequency switching function',
        cont6:'Product tracking and balance correction function, high detection accuracy and multi frequency switching function, can switch to different frequency detection for materials with multiple types and significant differences.',
        tit7:'Strong anti-interference ability',
        cont7:'Independently developed filtering circuit with strong anti-interference ability, designed with a drum anti deviation structure to prevent conveyor belt from running and tightening, and designed with a compact body. The conveyor belt can be quickly disassembled.',
        tit8:'IoT empowerment',
        cont8:'Equipped with product statistical analysis function, supporting data export to USB storage for IoT empowerment, easier management of superior hygiene performance,convenient cleaning, long service life, and an average lifespan of over 10 years.',
        // 通用金检机系列
        title3:'General Metal Detector',
        cont9:'Applied to the detection of metal foreign objects in non-metallic materials, Dookoo metal detection machines have high detection accuracy and a wide range of applications; Long service life, with an average lifespan of over 10 years.',
        // 宽幅金检机系列
        title4:'Wide Conveyor Metal Detector',
        cont10:'Applied to the detection of metal foreign objects in non-metallic materials, Dookoo metal detection machines have high detection accuracy and a wide range of applications; Wide width metal inspection machines are mostly used for metal detection of materials such as baking and grains.',
        // 铝膜磁性金检机系列
        title5:'Magnetic Metal Detector for Aluminum Foil/Metalized Foil',
        cont11:'Mainly designed for the detection of aluminum card and aluminum foil packaging materials, this machine has the advantages of lightweight structure, good anti-interference ability, and high sensitivity.',
        // 粉体管道自由落体金检机系列
        title6:'Gravity-fall Metal Detector',
        cont12:'Specially designed for the online detection of metal impurities in powder and granular materials, it is designed to meet the high sensitivity requirements of detecting broken wires in stainless steel screens for powder materials. It is installed on the material pipeline transportation line and can detect extremely small metal impurities.',
        // 液体泵压管道金检机系列1
        title7:'Pipeline Metal Detector Series 1',
        cont13:'This liquid pump pressure pipeline type metal detection machine is specifically designed for detecting high salt and high water content fluid materials such as jam, fillings, sauce bags, and central kitchen seasoning bags. It effectively solves the impact of aluminum buckle on ham sausage and achieves the best detection effect in high temperature and humidity environments.',
        // 液体泵压管道金检机系列2
        title8:'Pipeline Metal Detector Series 2',
        cont14:'This metal detection machine is specifically designed for metal detection in powder production environments with high humidity and harsh production environments, such as fish meal feed. By adding a shielding cover, the core components of the machine are maximally prevented from getting damp.',
    },

    // 重量检测
    zljc: {
        重量检测:'Checkweigher',
        // 产品价值
        title1:'The Product Value Of Dookoo Checkweigher',
        tit1:' High speed and precision dynamic weighing',
        cont1:"Up to 100 meters/minute dynamic weighing can be achieved, and the minimum detection accuracy can be ± 0.15g.",
        tit2:'Risk Control',
        cont2:"Maximize weight protection, reduce product complaints, protect brand image, and reduce consumer impact.",
        tit3:'RFID empowerment',
        cont3:"Visualization of weighing data, and traceability of weighing products.",
        // 优异之处
        title:'Advantages Of Dookoo Checkweigher',
        tit4:'Overall modular design',
        cont4:"Modular design for input, weighing, and output, facilitating installation and debugging. The rack adopts a horizontal surface and is welded with circular pipes to ensure no water accumulation or dust accumulation. The input and output countertops can be flexibly adjusted to ensure a smooth transition of the product.",
        tit5:'The tabletop is stable and reliable, meeting the requirements of hygiene and safety design',
        cont5:"The integrated design of the tabletop ensures a smooth transition of the product and achieves stable weighing. The drum adopts a structural design that prevents deviation, and Dookoo has its own imported high-precision machine tool to ensure processing accuracy and consistent reliable quality, preventing conveyor belt deviation. The width of the material inlet guide plate can be adjusted to facilitate docking with the production line. Optional labeling machine for weighing and labeling function. The contact gap between the moving parts on the table can be reduced to avoid injury to personnel. Post warning labels at prominent locations on the machine to ensure the safety of operators.",
        tit6:'Leading high weighing accuracy and weighing stability',
        cont6:"Adopting imported high dynamic response weighing sensors. Adopting industry-leading high-speed dynamic filtering algorithms to ensure the accuracy of dynamic weighing. Equipped with automatic zero point tracking function, ensuring zero point stability in the event of temperature changes, external vibrations, and other disturbances. The semi static weighing method supports stopping the weighing after the object is transferred to the belt and stabilizing for 1 second before continuing the transmission. It is suitable for scenarios with low speed and large spacing, further improving the weighing accuracy.",
        tit7:'Powerful software features',
        cont7:'The operation interface is simple and convenient. Customize the product learning process, target weight, and learning frequency customization. Support weight threshold triggering mode, suitable for products that are thin or easily reflective. Support for switching between Chinese and English. Support user permission management. Rich data statistics display function.',
        tit8:'Algorithm advantages',
        cont8:'Powerful data storage capability, capable of storing hundreds of thousands of formulas and continuously storing production data records for more than 5 years (the storage limit depends on the storage capacity of the hard drive, with standard 128g storage space and optional 128g-512g storage). The software can switch whether to use a labeling machine. Support switching between kilogram or gram units for display. Support the weighing method of subtracting tare weight.',
      // 高速度、高精度重量检测机
        title2:'High speed and precision Checkweigher',
        cont9:'Suitable for testing small packaging materials, with faster weight inspection speed and higher accuracy.',
        // 重量检测机
        title3:'Checkweigher',
        cont10:'Used for the detection of conventional materials, with adjustable weighing parameters and a wide range of applications, it can be customized according to customer needs.',
            // 重载重量检测机
        title4:'Heavy load Checkweigher',
        cont11:'Commonly used for pallet weighing or full box weighing.',
        // 金重检一体机
        title5:'Combo Metal Detector&Checkweigher',
        cont12:'Integrated design, meeting the dual needs of metal detection and Checkweigher.',
            // 重量分级检测机
        title6:'Weight grading inspection machine',
        cont13:'Automatically check, grade, and combine according to the matching requirements of material quantity and weight.',
        // 称重贴标机
        title7:'Weighing and labeling machine',
        cont14:'Integrated implementation of material transportation, weighing, and labeling functions.',
    },
     // 密封检测
     mfjc: {
        密封检测:'Sealing Detection',
        // 优异之处
        title1:'Advantages Of Dookoo Sealing Testing Machine',
        tit1:'Innovative multiple sealing detection products',
        cont1:'National invention patented products to meet the different needs of customers and products.',
        tit2:'Ensure that every product is guaranteed',
        cont2:'No spot checks, continuous online testing to ensure every product.',
        tit3:'Unique leak detection',
        cont3:'Say goodbye to traditional models and innovate non immersion and press type bag food leak detection.',
        tit4:'Accurate detection of pinhole level leaks',
        cont4:'The highest accuracy can reach 0.3mm.',
        tit5:'Powerful algorithm recognition function',
        cont5:'The algorithm distinguishes the detection results and is applicable to various packaging bags.',
        // 在线包装Sealing detection机-产品功能
        title2:'Online Packaging Sealing Testing Machine - Product Functions',
        mf_fun1:'Breaking through traditional detection methods, the intelligent HMI operating system accurately conducts continuous online detection, automatic identification, and elimination of airtight leaks in soft packaged food. Sound and light alarm for detected air leakage in soft packaged food bags.',
        mf_fun2:'The testing machine is equipped with qualified and unqualified product channels, which automatically separate and transport from each channel to the designated production control area.',
        // 在线包装密封检测机-产品优势
        title3:'Online Packaging Sealing Testing Machine - Product Advantages',
        mf_adv1:'This product can be connected for upstream and downstream transportation according to production process requirements, with various forms of inlet and outlet design to meet the testing requirements of airtight sealing.',
        mf_adv2:'The conveying capacity can reach 120 bags/minute (dual channel).',
        mf_adv3:'Covering various types of positive pressure, normal pressure, and weak negative pressure packaged soft packaged food, with a wide range of testing.',
        mf_adv4:'Adapt to multiple products, product upgrades do not affect the use of the production line, without the need for any hardware or software replacement, reducing usage costs.',
        mf_adv5:'The latest non-destructive quantitative leak detection technology has been adopted to solve the pain points of water bath destructive testing.',
        // 在线包装密封密封检测机-产品功能
        title4:'Online Packaging Sealing And Sealing Testing Machine - Product Functions',
        zx_fun1:'The new generation of bagged food leak detection equipment is designed to be small and exquisite, easy to use and operate, and is a non immersion and press type method for detecting bagged food leaks.',
        zx_fun2:'The leak detector does not immerse itself in water and breakthrough leak detection of bagged food through non pressing methods. Through precise small sample learning and algorithm processing, it automatically detects leaks in bagged food. By conducting airtight leak detection on a large number of bagged foods made of different materials, a formula for self developed bagged food leak detection is developed, which automatically stores leak detection data and enables one click management for leak detection of various bagged foods.',
        zx_fun3:'For food packaging bags of different materials, sizes, and thicknesses, only one versatile portable leak detector is needed.',
        //在线包装密封密封检测机-产品优势
        title5:'Online Packaging Sealing And Sealing Testing Machine - Product Advantages',
        zx_adv1:'The design is small and exquisite, easy to move, and easy to operate.',
        zx_adv2:'The algorithm distinguishes the detection results and is applicable to various packaging bags.',
        zx_adv3:'Matching management, suitable for various food packaging bags, 100% online testing.',
        zx_adv4:'After the food bag is placed by a robot or manually, the leak detection program is automatically completed.',
        zx_adv5:'Do not damage food bags, do not immerse in water, and do not use various auxiliary tooling molds.',
        zx_adv6:'Automatic light alarm, automatic recording of leakage detection quantity, short detection time.',
        zx_adv7:'Key components adopt international and domestic high-quality brands, with precise and stable detection parameters.',
        zx_adv8:'The material meets GMP requirements, is resistant to cleaning, easy to clean, and has no blind spots for cleaning.',
        zx_adv9:'It is a domestically initiated and nationally patented product.',
    },
 // 机器人码垛
 jqrmd: {
    机器人码垛机:'Robot Palletizing',
  //   机器人码垛-产品功能
  title1:'Robot Stacking - Product Functions',
  cont1:'The robot stacker is used for online stacking of cardboard boxes, integrating functional modules such as automatic pallet entry and exit, logistics transportation and positioning, robot grasping, and safety barriers. Meet the diverse needs of customers for palletizing cardboard boxes. At the same time, a multi-station stacking system can be equipped according to actual needs.',
  // 机器人码垛-产品优势
  title2:'Robot Stacking - Product Advantages',
  jqr_adv1:'Multiple labeling requirements can be selected.',
  jqr_adv2:'By using suction cups to adsorb and stack cardboard boxes, negative pressure detection is provided to avoid missing packaging.',
  jqr_adv3:'Safety gratings are installed around the stacking area to ensure the safety of operators during the production process.',
  jqr_adv4:'Robot brands adopt first tier brands such as ABB and FANUC (customers can specify).',
  jqr_adv5:'Equipped with disassembly function and stack entry and exit caching station, manual operation is more flexible.',
},
drug:{
    药品装备:'Pharmaceutical Equipment',
    // 立式超声波洗瓶机-产品功能
    lscs_title1:'Vertical Ultrasonic Bottle Washing Machine - Product Functions',
    lscs_fun1:'Used for cleaning bottles before the production of freeze-dried powder injection, powder injection, water injection, and oral liquid. It consists of a bottle inlet system, a cleaning (ultrasonic) system, a control system, a bottle outlet system, and other systems.',
    lscs_fun2:'During the process of gripping and flipping the bottle, the bottle clamping robot cleans the bottle. After cleaning, the bottle returns to an upright state and is output to the next process, automatically completing the cleaning process from bottle input, ultrasonic cleaning, external washing, internal washing to bottle output. The main transmission adopts servo drive, which also allows for a wide range of bottle type replacement robots to achieve.',
    lscs_fun3:'On the HMI of the cleaning process, one click formula management is used to complete the cleaning of packaging materials according to internationally and domestically validated cleaning processes, meeting the requirements for cleaning effectiveness verification of packaging materials internationally and domestically.',
    // 立式超声波洗瓶机-产品优势
    lscs_title2:'Vertical Ultrasonic Bottle Washing Machine - Product Advantages',
    lscs_adv1:'Servo driven main rotation and control, stable and long-lasting operation.',
    lscs_adv2:'Follow international and domestic guidelines to assist in designing cleaning intentions.',
    lscs_adv3:'Humanized design of maintenance areas to improve maintenance efficiency.',
    lscs_adv4:'The Q&D concept design cleans and protects the area to avoid cross contamination.',
    lscs_adv5:'After cleaning, the water pipeline should be managed in a way that reduces microbial growth.',
    lscs_adv6:'Automatically recycle injection water as circulating water to reduce water costs.',
    lscs_adv7:'The quick design of pipeline connection ensures convenient and fast maintenance, replacement, and maintenance.',
    lscs_adv8:'One click management of cleaning processes and cleaning program formulas to eliminate human errors.',
    lscs_adv9:'Monitor and automatically record cleaning pressure and temperature parameters, and trace key process parameters and operating documents.',
    // 热风循环灭菌隧道烘箱-产品功能
    rfxh_title1:'Hot Air Circulation Sterilization Tunnel Oven - Product Functions',
    rfxh_fun1:'The modular structure of the hot air circulation tunnel oven is divided into three process control areas: preheating zone, high-temperature sterilization zone, and cooling zone.',
    rfxh_fun2:'The preheating zone consists of a supply fan, a dehumidification fan, a manually adjustable damper, a medium efficiency filter, and a high-efficiency filter. The high-temperature sterilization area consists of a high-temperature resistant circulating fan, electric heater, and high-temperature high-efficiency filter, forming a hot air circulation system.',
    rfxh_fun3:'High temperature hot air flows through an efficient filter for filtration, heating, drying, and depyrogenation of the bottle; Excellent sterilization and depyrogenation curve, and a validation system that meets international requirements.',
    rfxh_fun4:'The cooling zone consists of a cooling system consisting of a supply fan, high-efficiency filter, surface cooler, and exhaust fan; The cooling area has wind pressure balance and SIP functions.',
    // 热风循环灭菌隧道烘箱-产品优势
    rfxh_title2:'Wind Circulation Sterilization Tunnel Oven - Product Advantages',
    rfxh_adv1:'High efficiency filter negative pressure sealing design.',
    rfxh_adv2:'Various process interfaces that meet validation requirements.',
    rfxh_adv3:'Key process parameters can be traced and formulated for management.',
    rfxh_adv4:'Online cleaning of the mesh belt, providing cleaning verification instructions.',
    rfxh_adv5:'Dual channel temperature sensors are used for temperature control and recording.',
    rfxh_adv6:'The pressure difference balance design with automatic control avoids the risk of pressure difference fluctuations.',
    rfxh_adv7:'The modular design of the three section structure ensures good uniformity during no-load and full load verification.',
    rfxh_adv8:'Warning lights, FH value guarantee for different operating states, HMI one click formula management.',
    rfxh_adv9:'The intended use follows the requirements of GMP, cGMP, EUGMP, FDA, GAMP5, and 21CFRPart11.',
    // 罐装加塞机-产品功能
    gzjs_title1:'Canning Stopper - Product Functions',
    gzjs_fun1:'Applied to pharmaceutical companies for research and development, small-scale trials, Chinese style and large-scale production of filling, with speeds of 50 bottles/minute, 100 bottles/minute, 200 bottles/minute, 400 bottles/minute, 500 bottles/minute, etc.',
    gzjs_fun2:'According to the characteristics of the liquid medicine, a dual pump system, peristaltic pump, plunger pump, time pressure method, etc. are used for filling, and isolation protection methods include RABS, cRABS, ISOLATOR. According to process control requirements, top inlet and side inlet methods are used.',
    gzjs_fun3:'Adopting a unique filling precision full process control design, it meets the requirements of residual liquid control before filling and tail liquid control before production ends.',
    // 罐装加塞机-产品优势
    gzjs_title2:'Canning Stopper - Product Advantages',
    gzjs_adv1:'Apply freeze-drying and water injection filling, filling system CIP/SIP.',
    gzjs_adv2:'Filling solutions that meet different pharmaceutical industry characteristics, multiple solutions for sterile transfer of rubber stoppers, providing A-level monitoring interfaces and distribution plans, filling solutions with CXO characteristics, and usage plans for RTU and SUS systems.',
    gzjs_adv3:'The specifications are compatible and practical, with 2-100ml specifications and different filling quantities. Using high-precision flow meters to replace traditional electronic scales, changing the full weight from intermittent motion to continuous motion, and improving work efficiency.',
    gzjs_adv4:'The entire machine adopts large module helical gears for transmission, which are combined with metal and nylon gears for stable operation and low noise. Switching between ordinary and vacuum plug pressing methods can be achieved by quickly replacing some parts.',

    // 轧盖机-产品功能
    ygj_title1:'Capping Machine - Product Functions',
    ygj_fun1:'Different specifications, different speeds, and matching methods are managed to achieve upstream and downstream connections. The aluminum cover transfer design is arranged according to the requirements of "sterile capping" and "clean capping".',
    ygj_fun2:'The capping machine is equipped with an aluminum cap self-cleaning temporary storage platform, and the air inlet method of the isolation protection system can be designed according to the workshop. Equipped with an aluminum chip collection system, rubber plug height, and uncovered identification function.',

    // 轧盖机-产品优势
    ygj_title2:'Capping Machine - Product Advantages',
    ygj_adv1:'Compatible with a wide range of specifications: 2-100ml.',
    ygj_adv2:'Base pressure program adjustment and configuration management.',
    ygj_adv3:'The capping process is completed through autobiography and revolution.',
    ygj_adv4:'Reduce labor intensity, automatic aluminum cover lifting device.',
    ygj_adv5:'Multiple capping methods, single blade, large single blade, and three blade.',
    ygj_adv6:'Front and rear removal stations, automatic removal without plugs or abnormal rubber plugs, and without covers.',
    // 在线灯检机-产品功能
    zxdj_title1:'Online Light Inspection Machine - Product Advantages',
    zxdj_fun1:'Multiple testing items include capping quality, defect detection of freeze-dried products, presence and damage of rubber stoppers, presence of powder on the stoppers, cracks and scratches on the bottle neck and body, as well as contamination inside and outside the penicillin bottle.',
    zxdj_fun2:'Bottle running has internal circulation, which can run a large number of bottles for a long time.',
    zxdj_fun3:'The material meets GMP requirements, is resistant to cleaning, easy to clean, and has no blind spots for cleaning.',
    zxdj_fun4:'Key components adopt international and domestic high-quality brands, with precise and stable detection parameters.',
    // 在线灯检机-产品优势
    zxdj_title2:'Online Light Inspection Machine - Product Functions',
    zxdj_adv1:'The integration of the electrical cabinet and the machine reduces the risk of communication failure by reducing cables.',
    zxdj_adv2:'The camera adopts a worm gear and worm structure for round-trip tracking with a pendulum wheel, which is more robust in structure.',
    zxdj_adv3:'The transmission adopts large modulus, helical gears, and nylon gears for low noise and more stability.',
    zxdj_adv4:'The lifting cam and camera are inverted, which does not occupy desktop space. The height of bottles of different specifications can be automatically adjusted by formula.',
},

 /****解决方案****/
 solve: {
    topTitle:'Provide Customers With Efficient, Stable, Visible, And Intelligent Modern Chemical Plants',
    // 智慧工厂建设与运营
    navTitle1:'Smart Factory Construction And Operation',
    cont1:'Dookoo provides business consulting, factory planning and design, engineering construction, equipment integration, IoT empowerment, operation management, technical support and maintenance, core components and other services for mid to high-end pharmaceutical customers. We are closer to the rapidly changing market demand, guided by market demand, and create value for customers.',
    // 智能自动化装备全链路服务
    navTitle2:'Intelligent Automation Equipment Full Link Service',
    cont2_1:'Dookoo can provide customers with intelligent packaging, measuring systems, leveling systems, Metal Detector，X-ray Inspection System, Checkweigher, sealing detection, integrated machine for opening and sealing boxes, stacking systems, and other integrated solutions for post packaging automation and intelligence.',
    cont2_2:'Customers can choose matching packaging machines, testing equipment, robot palletizing and other combination wiring based on the characteristics of the material, packaging bag form, testing requirements, and palletizing type. The advantages of the overall solution lie in its systematicity, reliability, simplicity, and efficiency. Providing turnkey projects to customers is beneficial for making them more convenient, applicable, economical, and efficient.',
    cont2_3:'It can also be equipped with a multidisciplinary production line IoT system to achieve digital driving, identify production pain points, connect operations, management, production, warehousing and logistics, and other links. Build a digital factory to make it easy for employees to work and for leaders to have poetry and distance.',
    更多:'more',
    tit1_1:'Intelligent pckaging machine',
    cont3_1_1:"Solid packaging machine - Qianqianbao: designed and developed specifically for large-sized PE film packaging; Used for packaging various materials of frozen food, such as frozen meatballs, steamed buns, French fries, chicken nuggets, chicken wings, chicken legs, etc; Can achieve product compatibility of 0.5KG-10KG.",
    cont3_1_2:"Liquid packaging machine: suitable for catering sauces; Seasoning; Jam; Various fillings such as meat filling, vegetable filling, lotus seed paste, shrimp paste, fish paste, bean paste, winter seed paste, etc; And packaging of liquid materials such as Congee and soybean milk. Can achieve a packaging range of 0.5KG-5KG.",
    cont3_1_3:"Small material packaging machine: mainly used for small material packaging of particles, liquids, and powders in food, daily chemicals, pharmaceuticals, etc., such as instant noodle seasoning bags, oil bags, etc. Packaging range: 0.5ml-60ml or (g).",
    tit1_2:'Measurement system',
    cont3_2:'This device is used in conjunction with a packaging machine. Mainly composed of rack group, differential conveying group, edge blocking group, matching vulnerability group, and induction photoelectric group. By combining the elevator with feeding, it is simple and efficient. The edge blocking group can adjust the width of the material blocking channel for different products, with strong compatibility. When transporting products, the speed difference between different conveying groups separates the products, and photoelectric induction counting is performed at the outlet, ensuring stability and reliability. Matching vulnerabilities are intelligently matched based on the numerical values set for each package, making it intelligent and convenient.',
    tit1_3:'Leveling system',
    cont3_3:'The entire machine adopts a highly integrated design that integrates leveling, bag breaking detection, and removal, achieving the shaping and damage detection of bagged loose materials. According to different material requirements, modules can be replaced to meet the flattening and shaping requirements of bagged products.',
    tit1_4:'Metal Detector',
    cont3_4:'Used for the detection and removal of metal foreign objects in non-metallic materials. ',
    tit1_5:'X-ray Inspection System',
    cont3_5:'Detect foreign objects (stones, glass, metal, etc.), missing, damaged, irregular, material counting, edge sealing, cracks, etc. in the material, and select different removal methods to remove unqualified materials. ',
    tit1_6:' Checkweigher machine',
    cont3_6:'Realize dynamic weighing of materials with high speed, precision, and stability. ',
    tit1_7:'Opening, packaging, and sealing integrated machine',
    cont3_7:'Realize the automatic stacking and packing function of bagged products. ',
    tit1_8:'Robot stacking',
    cont3_8:'The robot stacker is used for online stacking of cardboard boxes, integrating functional modules such as automatic pallet entry and exit, logistics transportation and positioning, robot grasping, and safety barriers. ',
    // 注射剂无菌灌装联动线
    navTitle3:'Injection Aseptic Filling Linkage Line',
    cont4_1:'Suitable for filling processes of different injection types: Widely suitable for filling products such as freeze-dried powder injection, powder injection, water injection, oral liquid, etc. Whether it is ordinary or characteristic liquid medicine, it can be accurately and aseptically filled while maintaining the stability of the liquid medicine.',
    cont4_2:'Dookoo Leading High end Manufacturing Localization: With the innovative research and development of top domestic teams, breakthroughs in multiple core technologies have not only improved the efficiency and accuracy of sterile filling, but also replaced many imported technologies and components, resulting in lower equipment cost. The integrated model of self research, self production, and self sales also ensures the professionalism and convenience of after-sales operation and maintenance.',
    cont4_3:'Multiple specifications of equipment types meet different filling scenarios: providing sterile filling linkage lines with filling speeds of 50 bottles/minute, 100 bottles/minute, 200-400 bottles/minute, and 500 bottles/minute, suitable for research and development, small and medium-sized trials, as well as large-scale sterile filling requirements.',
    cont4_4:'Comprehensive prevention of pollution hazards: Human intervention is the most common source of pollution in aseptic production processes, and automated filling processes can greatly reduce the risks brought by manual operations. At the same time, the unique design of the equipment reduces the need for tool replacement structures, which can effectively prevent the risk of contamination caused by foreign objects falling.',
    cont4_5:'The aseptic filling linkage line products include: fully automatic vertical ultrasonic bottle washing machine, efficient hot air circulation sterilization tunnel oven, advanced filling and stoppering machine, and stable and controllable capping machine. The products on the linkage line can be used as independent products, or as a combination of integrated products on the linkage line, or combined with star detection products from multiple departments and packaging modules to form a complete production line solution.',
    tit2_1:' Multiple technological breakthroughs',
    cont5_1:'Unique pharmaceutical canning precision process control system and pioneering high-speed weighing function. ',
    tit2_2:'Multi functional and multi scenario',
    cont5_2:'Online cleaning and sterilization of the filling system.',
    tit2_3:'Automation and Intelligence',
    cont5_3:'Effectively avoiding human intervention and reducing the possibility of pollution from the source. ',
    tit2_4:'Compatible with 2ml-100ml',
    cont5_4:'Specification compatibility light, supporting multiple canning methods.',
    tit2_5:'High precision and stability',
    cont5_5:'Servo motor drive ensures accuracy. ',
    tit2_6:'Application Scenario',
    cont5_6:'Injectable drugs, oral liquids, freeze-dried drugs. ',
},

  /*****数智工厂*****/
  factory:{
    topTitle1_1:'Intelligent Factory Driven Lean Management',
    topTitle1_2:'Intelligent factory cloud platform+multi cloud and multi object IoT platform',
    智能制造:'Intelligent Manufacturing',
    cont1_1:'Long term focus on intelligent manufacturing systems, virtual simulation to create core technologies for enterprises, committed to exploring intelligent manufacturing systems.',
    智慧能源:'Smart Energy',
    cont1_2:'Intelligent maintenance precise analysis, improving energy efficiency from multiple perspectives, optimizing equipment performance throughout its lifecycle, reducing costs, and reducing carbon footprint.',
    智慧资产:'Smart Assets',
    cont1_3:'The knowledge that is transformed into market value is all the knowledge and skills that a company can generate profits. The value of smart capital is where human wisdom lies.',
    场景方案:'Scenario Plan',
    cont1_4:'It can be used for user design, milestones, user applications, and can achieve the collection of users and their living environment, as well as user access methods.',
    // 智能制造
    // 设备物联网平台
    设备物联网平台:'Device IoT Platform',
    cont2_1:'Intelligent hardware management',
    cont2_2:'Device Data Dashboard',
    cont2_3:'Equipment energy consumption management',
    cont2_4:'Fault warning processing',
    cont2_5:'Equipment ledger',
    // 仓储管理系统(WMS)
    仓储管理系统:'Warehouse Management System (WMS)',
    cont3_1:'Warehouse management',
    cont3_2:'device management',
    cont3_3:'data management',
    cont3_4:'material management',
    cont3_5:'quality control',
    cont3_6:'order management',
    cont3_7:'Production Execution',
    // 制造运营管理(MES)
    制造运营管理:'Manufacturing Operations Management (MES)',
    cont4_1:'Manufacturing Resource Allocation and Status Control',
    cont4_2:'Maintenance and operation management',
    cont4_3:'Inventory operation management',
    cont4_4:'Advanced scheduling management',
    cont4_5:'energy management',
    cont4_6:'quality control',
    // 高级计划排程(APS)
    高级计划排程:'APS',
    cont5_1:'Basic data',
    cont5_2:'Planned consistency',
    cont5_3:'Core algorithm',
    cont5_4:'Product process',
    cont5_5:'Planning strategy',
    cont5_6:'Plan Visualization',
    // 智慧能源
    // 能源管理(EMS)
    能源管理:'Energy Management (EMS)',
    cont7_1:'Monitoring and Collection',
    cont7_2:'Data analysis and optimization',
    cont7_3:'Energy dispatch and control',
    cont7_4:'Fault detection and safety protection',
    cont7_5:'Key energy consuming equipment management',

    // 智慧资产
    // 设备数字运维
    设备数字运维:'Digital operation and maintenance of equipment',
    cont8_1:'Production environment risk monitoring',
    cont8_2:'Equipment operation monitoring',
    cont8_3:'Production equipment anomaly prediction',
    cont8_4:'Equipment Predictive Maintenance Solution',
    cont8_5:'Intelligent analysis of modeling',
    // 设备效率助手
    设备效率助手:'Device Efficiency Assistant',
    cont9_1:'Control load event rate',
    cont9_2:'Control crop growth rate',
    cont9_3:'Improve productivity efficiency',
    cont9_4:'Yield analysis',
    cont9_5:'Evaluate overall equipment efficiency',
    // 设备健康(PHM)
    设备健康:'PHM',
    cont10_1:'Equipment Health Management',
    cont10_2:'Digitization of Service Management',
    cont10_3:'Automatic operation and maintenance of key devices',
    cont10_4:'Energy efficiency management of motor drive control system',
    // 企业资产管理(EAM)
    企业资产管理:'Enterprise Asset Management (EAM)',
    cont11_1:'Rich data asset types',
    cont11_2:'Intelligent directory activation management',
    cont11_3:'Diversified data service methods',
    cont11_4:'Full process data security control',
    cont11_5:'Visual Data Asset Service Portal',
    // 场景方案
    生产场景解决方案:'Production Scenario Solutions',
    content1:'Improving production efficiency and product quality is crucial for the development of enterprises, and optimizing production processes, improving personnel quality, and introducing high-end technology are effective ways to achieve this goal. Therefore, enterprises should seize the opportunity to gradually improve various aspects of the production site from multiple perspectives, in order to achieve steady growth and development of the enterprise.',
    智慧园区场景解决方案:'Smart Park Scenario Solution',
    content2:'The planning and construction of parks are increasingly focusing on various basic supporting facilities to better serve and promote the development of high-tech industries. Especially focusing on the informatization construction of industrial parks, building an interconnected and resource sharing information resource network, and using informatization to drive industrialization is an important aspect of accelerating the development of industrial parks.',
    数字化服务运营场景:'Digital Service Operation Scenarios',
    content3:'The use of information technology and digital tools to achieve enterprise operational activities mainly addresses digital service operation scenarios from four aspects: digital marketing, digital supply chain management, digital customer relationship management, and digital production management.',
},
/*** 协同创新 ***/
service:{
    topTitle:'Engine For Collaborative Innovation To Drive Industry Development',
    客制化研发中心:'Customized R&D Center',
    产学研合作:'Industry University Research Cooperation',
    高质量发展:'High Quality Development',
},
    /**** ****/
    user: {
        name:'中文',
        login:'login',
        register:'register',
        loginUsername:'please input email or phone',
    }
}
