module.exports = {
    // 导航菜单
    header: {
        name:'English',
        Home: '首页',
       /****走进多科****/
        EnteringDooKoo:'走进多科',
        企业介绍:'企业介绍',
        资质证书:'资质证书',
        新闻中心:'新闻中心',
        社会责任:'社会责任',
        /****产品中心****/
        ProductCenter:'产品中心',
        ///食品
        食品装备:'食品装备',
        智能包装:'智能包装',
        异物检测:'异物检测',
        金属检测:'金属检测',
        重量检测:'重量检测',
        密封检测:'密封检测',
        机器人码垛:'机器人码垛',
        ///药品
        药品装备:'药品装备',
        立式超声波洗瓶机:'立式超声波洗瓶机',
        热风循环隧道烘箱:'热风循环隧道烘箱',
        罐装加塞机:'罐装加塞机',
        轧盖机:'轧盖机',
        在线灯检机:'在线灯检机',
        /****解决方案****/
        Solution:'解决方案',
        智慧工厂建设与运营:'智慧工厂建设与运营',
        智能自动化装备全链路服务:'智能自动化装备全链路服务',
        注射剂无菌灌装联动线:'注射剂无菌灌装联动线',
        /****数智工厂****/
        DigitalIntelligenceFactory:'数智工厂',
        设备物联网平台:'设备物联网平台',
        制造运营管理:'制造运营管理(MES)',
        高级计划排程:'高级计划排程(APS)',
        仓储管理系统:'仓储管理系统(WMS)',
        能源管理:'能源管理(EMS)',
        设备数字运维:'设备数字运维',
        设备效率助手:'设备效率助手',
        设备健康:'设备健康(PHM)',
        企业资产管理:'企业资产管理(EAM)',
        生产场景解决方案:'生产场景解决方案',
        智慧园区场景解决方案:'智慧园区场景解决方案',
        数字化服务运营场景:'数字化服务运营场景',
        /****协同创新****/
        CollaborativeInnovation:'协同创新',
        客制化研发中心:'客制化研发中心',
        产学研合作:'产学研合作',
        高质量发展:'高质量发展',
    },
    /****首页****/
    // tab
    dktablist: {
        智能装备:'智能装备',
        tit1:'智能包装',
        cont1:'多科高效、稳定、环保的包装新科技，广泛满足各种内容物的包装，无论是酱料、调味料、馅料、粥、豆浆等流体食品，还是颗粒、块状、条状、粉体的固体食物，都能按照生产需求。',
        tit2:'X-ray异物检测',
        cont2:'产品通过不同物料对X射线吸收率不同，在X射线上体现出灰度明暗不同的图像,将物料中的异物（石头、玻璃、金属等）,缺失，破损，异形，物料计数，封边，裂缝等检测出来。',
        tit3:'金属检测',
        cont3:'用于非金属物料中金属异物的检测和剔除。粉体管道式自由落体金属检测适用于不同生产时期中的异物混入问题，提供丰富的检测产品设备。',
        解决方案:'解决方案',
        tit4:'智慧工厂建设与运营',
        cont4:'多科为中高端食药客户提供业务咨询，整厂规划与设计，工程建设，设备集成，物联网赋能，技术支持，核心零部件等服务。我们更贴近高速变化的市场需求，为客户创造价值。',
        tit5:'智能自动化装备全链路服务',
        cont5:'多科可为客户提供智能包装、计量系统、整平系统、金属检测、异物检测、重量检测、密封检测、开装封箱一体机、码垛系统等后段包装自动化及智能化整体解决方案。',
        tit6:'注射剂无菌灌装联动线',
        cont6:'多科注射剂无菌灌装联动线产品包括：全自动的立式超声波洗瓶机、高效的热风循环灭菌隧道式烘箱、先进的灌装加塞机，以及稳定可控的轧盖机。',
        物联网:'物联网',
        tit7:'智能制造',
        cont7:'长期围绕智能制造系统,虛拟仿真打造企业核心技术,致力于发掘智能制造系统。',
        tit8:'智慧能源',
        cont8:'智能维护精准分析,从多角度提升能效，优化设备在其生命周期中的性能,降低成本并减少碳足迹',
        tit9:'智慧资产',
        cont9:'转化为市场价值的知识，是企业所有能够带来利润的知识和技能。智慧资本的价值，就是人的智慧所在。'
    },
     // 走进多科
     enterdk:{
        走进多科:'走进多科',
        p1:'多科，创立于2008年，全国领先的食品智慧工厂服务提供商。包含：业务咨询，整厂规划与设计，工程建设，设备集成，物联网赋能，运营管理，技术服务与支持等。',
        p2:'多科旗下5家子公司。设立上海（上海虹桥绿谷人才港）和常熟两个研发中心，与中国工程院，江苏大学，武汉大学等知名院校合作，拥有博士，硕士为核心，涵盖机械、电气自动化、电子、软件工程、算法、图像识别、大数据、人工智能等8个专业的50余人研发团队。目前已获得专利荣誉一百多项；是武汉大学十三五国家重点研发计划：食品安全大数据关键技术智能装备基地；江苏大学十三五国家重点研发计划：中式自动化中央厨房成套装备研发与示范智能装备基地。',
        p3:'多科设有常熟2大生产基地和辐射全国办事处。第一基地是占地2万平米的现代化工厂，2022年，投资3.2亿人民币，新建总面积6.4万平二期生产基地，建成后预计年产4100台工业自动控制系统装置。',
        p4:'我们密切关注社会、政府以及全球趋势，携手客户与供应商通力合作，创造一个有利于可持续发展的创新环境，助推整个行业实现共同转型，一起创建更美好的未来。',
    },
      // 历史历程
      history:{
        y1:'深耕食药工业',
        y2:'专利&荣誉',
        y3:'远销国家&地区',
        y4:'服务客户',
        num1:15,
        num2:120,
        num3:70,
        num4:8000,
        years:'年',
    },
    // 合作客户
    custom:{
        custom:'合作客户',
    },
    // 资质荣誉
    honer:{
        honer:'资质荣誉',
    },
    // 新闻中心
    center:{
        center:'新闻中心',
        tit1:'多科参加 ｜ 慕尼黑IBA2023',
        cont1:'慕尼黑国际烘培技术博览会（IBA）于10月22日盛大开幕！2023年10月22-26日，全球烘培领域专业人士齐聚德国慕尼黑展览中心，讨论烘培与糕点制作工艺。',
        cont2:"热烈庆祝中华人民共和国成立74周年！",
        tit2:'国泰民安，万家团圆',
        tit3:'热烈庆祝中华人民共和国成立74周年！',
        tit4:'秋已至，何为食?盘点下好吃的月饼有哪些?',
        tit5:'多科与嘉兴未来食品研究院成立联合实验室',
        tit6:'喜报｜上海味享多厨科技有限公司成立',
        tit7:'展会预告 ｜ ProPak China 2023',
        tit8:'多科参展｜CIPM2023(秋)中国国际制药机械博览会',
        cont3:'第63届（2023年秋季）全国制药机械博览会暨2023（秋季）中国国际机械制药博览会将于2023年11月13日-15日在厦门国际博览中心举办，多科携注射剂无菌灌装联动线整体解决方案及灌装机，灯检机、包装机、X-ray异物检测机等多款单机装备亮相1-70展位。',
    },

    /****底部导航，联系我们****/
    contact:{
        联系我们:'联系我们',
        总部地址: '总部地址：上海市闵行区虹桥绿谷广场G幢618室',
        生产基地:'生产基地：江苏省常熟市虞山高新区多福路1号',
        生产基地2:'江苏省常熟市东山路25号',
        电话:'电      话： 400-800-9801',
        传真:'传      真： 021-50802583',
        邮箱:'邮      箱： dookoo_nl@126.com',
        公众号:'公众号', 
        商务咨询:'商务咨询微信',
        姓名:'姓名',
        手机号:'手机号',
        咨询留言:'咨询留言',
        提交:'确认提交',
        公司:'上海多科电子科技有限公司',
        版权所有:'版权所有',
        备案号:'备案号',
    },

    /****走进多科****/
    enterDk:{
        topTitle:'智能装备未来生活',
        公司资质:'公司资质',
        专利证书:'专利证书',
        社会责任:'社会责任',
        // 社会责任
        title1:'是企业，更是社会的企业',
        title2:'多科积极推动基于业务的社会于环境价值',
        tit1:'守护安全',
        cont1:'消费者安全：多科守护着48个行业和上亿消费者食品安全。帮助客户识别关键危险控制点：多科协助不同行业的客户识别并突破其生产过程中的关键危害控制点。产品满足GMP标准：最大化减少外带工具使用，降低包装食安风险。',
        tit2:'环境友好',
        cont2:'技术助力环保升级：让技术成为环保驱动，推动更环保的食品包装材料广泛应用。多科的包装设备适用于更环保的PE膜，与复合膜相比，每个包材约节省38%的原料和50%的能耗。',
        tit3:'智造未来',
        cont3:'协同创新：始于洞察，敬于强干，与客户共创更智能高效的解决方案。 超1/3的多科产品由行业头部企业深度共创打造，降低供需失配带来的社会研发成本。硬核研造：多科勇于挑战自我，不断探索新的可能性，用技术实力提高行业标准。',
        tit4:'协同善治',
        cont4:'对待“合作伙伴”信守承诺：我们承诺员工工资、供应商采购款项绝不逾期支付，严格按照合同要求，准时支付款项。反歧视与性别平等：我们通过培训机制，提高女性工作能力，也为他们创造职业发展的空间和机会。我们严格律己、合规经营，遵守道德和法律的标准。',
        tit5:'员工关怀',
        cont5:'我们始终关怀员工安康 ，在职业上提供创新展现机会。多科建立以来，重大安全事故为0，100%的产线员工接受安全培训和购买保险 。人机体验：我们在乎机器使用者的安全体验、操作方便、使用效率。',
    },

   /****产品中心****/
    product:{
        topTitle:'从细节开始，只为制造出优秀、卓越的产品',
        tit1:'智能包装',
        cont1:'多科高效、稳定、美观、环保的包装新科技广泛满足各种物料的包装，无论是酱料、调味料、馅料、粥、豆浆等流体食品，还是颗粒、块状、条状、粉体的固体食物，都能按照实际生产需求，使用单机或集成产线，轻松实现 “量体裁衣”。不断趋于多样、精细及可持续，满足多元化包装需求。',
        tit2:'X-ray异物检测',
        cont2:'产品通过不同物料对X射线吸收率不同，在X射线图像上体现出灰度明暗不同的图像，将物料中的异物（石头、玻璃、金属、陶瓷等），缺失，破损，异形，物料计数，封边，裂缝等检测出来，并选配不同的剔除方式，将不合格的物料剔除。我们致力于让食品的生产及产品更加安全，承诺始终追求最极致的品质与保证：只提供最安全的、最好的。',
        tit3:'金属检测',
        cont3:'用于非金属物料中金属异物的检测和剔除。金属检测适用于不同生产十七的异物混入问题，提供丰富的检测产品设备，在加工包装前或生产包装后都能即使有效的检测异物情况，从而大幅度保护产品质量。多科金属检测机采用先进的相位跟踪功能和多频切换功能，检测精度高，检测范围广。',
        tit4:'重量检测',
        cont4:'实现物料高速度，高精度，高稳定性的动态称重。领先的高称重速度和高称重精度，最高可实现100米/min的动态称重，最小可实现±0.15g检测精度。整体模块化设计；台面稳定可靠，满足稳定称重，卫生及安全设计；独特的皮带防跑偏技术；强大的软件功能及算法优势；可以存储数十万种配方、连续存储5年以上的生产数据记录。',
        tit5:'密封检测',
        cont5:'多科创新多款密封检测装备，满足客户及产品的不同检测需求；在线包装密封检测机采用智慧的HMI 的操作系统，精准的对软包装食品的气密漏气进行逐个连续的在线检测，自动识别，自动剔除。新一代袋装食品的泄漏检测仪，设计小巧玲珑，使用操作便捷，非浸水式、按压式袋装食品泄漏检测的方式，国家发明专利产品。',
        tit6:'机器人码垛',
        cont6:'机器人码垛机用于纸箱的在线码垛，集成了栈板自动进出、物流转运及定位、机器人抓取、安全护栏等功能模块。满足客户对纸箱码垛的多种需求。同时可根据实际需求配备多工位码垛系统。通过吸盘吸附实现纸箱码垛，配负压检测，避免漏装。码垛周边配安全光栅，确保生产过程中操作人员的安全、机器人品牌采用AB/FANUC等一线品牌（客户可指定）、配备拆盘功能及进出栈缓存工位，人工操作更加灵活。',
        tit7:'立式超声波洗瓶机',
        cont7:'用于冻干粉针、粉针、水针、口服液生产之前对瓶子的清洗。由进瓶系统、清洗（超声波）系统、控制系统、出瓶系统等系统构成，夹瓶机械手夹持运行并翻转瓶子过程中清洗瓶子，清洗完成后瓶子恢复成正立状态，被输出至下道工序 , 自动完成从进瓶、超声波清洗、外洗、内洗到出瓶的清洗过程，主传动采用伺服驱动，兼容大范围瓶型更换机械手实现；清洗工艺 HMI 上一键配方管理，按照国际、国内验证的清洗工艺完成包材的清洗，符合国际、国内对包材清洗的清洗效果验证要求。',
        tit8:'热风循环隧道烘箱',
        cont8:'模块化结构的热风循环隧道烘箱，分为预热区、高温灭菌区和冷却区三个工艺控制区域；预热区由送风机、抽湿风机、手动调节风门、中效过滤器和高效过滤器等组成。高温灭菌区由耐高温循环风机、电加热器和高温高效过滤器等组成一个热风循环系统。高温热空气流经高效过滤器过滤，对瓶子加热干燥和去热原；优良的灭菌去热原曲线，符合国际化要求的验证体系；冷却区由送风机、高效过滤器、表冷器和排风机等组成一个冷却系统；冷却区有风压平衡、SIP 功能。',
        tit9:'罐装加塞机',
        cont9:'应用于药企的研发、小试、中式及规模生产的灌装，其速度分别为50瓶/分钟、100瓶/分钟、200瓶/分钟、400瓶/分钟、500瓶/分钟等，根据药液特性采用双泵系统、蠕动泵、柱塞泵、时间压力法等进行灌装，隔离保护有RABS、cRABS、ISOLATOR方式，根据工艺控制要求，采用顶进风、侧进风方式。采用独特的灌装精度全程控制设计，满足灌装前残液控制、生产结束前尾液控制的要求。',
        tit10:'轧盖机',
        cont10:'不同规格的不同速度，配方式管理，实现上下游连线，铝盖转移设计根据“无菌轧盖”、“洁净轧盖”的要求进行方案布局。轧盖机设置有铝盖自净暂存台，隔离保护系统进风方式可根据车间进行设计。具有铝屑收集系统，胶塞高度、无盖识别功能。',
        tit11:'灯检机',
        cont11:'电气柜与机台集成到一起减少电缆线，降低通讯失败风险。升降凸轮及相机倒挂式，不占桌面空间，不同规格瓶子高度可配方自动调整高度。传动采用大模数，斜齿轮，搭配尼龙齿轮使用，噪音低，更稳定。相机跟拍采用摆轮往返跟踪用蜗轮蜗杆结构，结构更强悍。跑瓶有内循环，可以长时间大量跑瓶、检测项多：轧盖质量，冻干产品瑕疵检测，胶塞有无，损坏、胶塞上是否有药粉，瓶颈和瓶身的裂痕、划痕及西林瓶内外的污染物等。关键部件采用国际、国内优质品牌，检测参数精准和稳定、材质符合 GMP 要求，耐清洁、易清洗、无清洁盲区。',
        查看详情:'查看详情',
        立即联系:'立即联系',
        //产品中心 系列详情页
        智能包装系列:'产品中心-智能包装系列',
        异物检测系列:'产品中心-异物检测系列',
        金属检测系列:'产品中心-金属检测系列',
        重量检测系列:'产品中心-重量检测系列',
        密封检测系列:'产品中心-密封检测系列',
        机器人码垛机系列:'产品中心-机器人码垛机',
        药品装备系列:'产品中心-药品装备',
        //产品中心 立即联系弹框
        立即联系:'立即联系',
        取消:'取消',
        contact:'若您希望进一步了解多科产品和服务，请提交以下信息，我们马上跟您取得联系:',
        姓名:'姓名',
        手机号:'手机号',
        公司名称:'公司名称',
        所属行业:'所属行业',
        留言:'若您有其他想了解的问题，欢迎给我们留言',
        // 输入框提示
        姓名提示:'请输入您的姓名',
        手机号提示:'请输入您的手机号',
        确认信息:'确定要提交您输入的信息吗',
         // 提示框
         提示:'提示',
         提示内容:'请输入您的姓名和手机号!',
        提交中:'提交中...',
    },

    // 智能包装
    znbz:{
        // 产品的介绍
        智能包装:'智能包装',
        title1:'高效、稳定、美观、环保的包装新科技',
        title2:'多科敏锐地发现了包装需求正不断趋于多样、精细及可持续，并以此作为升级包装技术、创新包装解决方案的起点，满足多元化包装需求。',
        tit1:'满足固体、液体、颗粒等产品',
        cont1:'广泛满足各种物料的包装，无论是酱料、调味料、馅料、粥、豆浆等流体食品，还是颗粒、块状、条状、粉体的固体食物，都能按照您的实际生产需求，使用单机或集成产线，轻松实现 “量体裁衣”。',
        tit2:'通用性高且功能完备',
        cont2:'适用于不同行业，如烘焙、冷藏和冷冻食品、巧克力和糖果、医药和保健以及干货食品，采用自动化解决方案满足不同生产速度产线的实际要求。',
        tit3:'兼具安全和美观的产品包装',
        cont3:'兼容多样材质的包材和袋型，包括枕式袋、立式袋、盒式袋，在提升封口稳定性和密封性的同时，加强了制袋和封口的美观性，高效打造更具吸引力的产品包装。',
        tit4:'保证包装品质的稳定性和一致性',
        cont4:'智能包装设备融合了传感、通信、人工智能等技术，一键操作实现自动制袋、送料、充填计量、封口切断、充气、打印、成品输出、计数等操作，减少人工操作带来的不确定性，保证包装品质始终如一。',
        tit5:'精准操控、降低生产成本',
        cont5:'高速运转的包装过程中，精准的走膜控制不仅能有效减少包材使用量，降低生产成本，还能提确保单品的包装质量，降低原料耗损和废品率。',
        tit6:'非标定制能满足个性化制造需求',
        cont6:'从客户实际需求出发，结合专业的生产线空间规划、动线最佳化设计、生产人力配置建议，可集成个性化包装和检测整体解决方案，助力食品行业智能制造升级。',
        // 智能包装机产品功能
        tit7:'智能包装机金钱豹-产品功能',
        znls1:'该设备专为大尺寸PE膜包装研发设计，循环可持续',
        znls2:'主要针对冷冻食品行业各类物料的包装，如速冻丸子、包子、薯条、鸡块、鸡中翅、鸡腿等。',
        znls3:'可实现固体袋装产品的自动充填封口功能。包装0.5KG-10KG的产品兼容性。',
        znls4:'全伺服驱动系统，实现大包装高速包装。配合多头组合秤、量杯、螺杆秤等系统使用。',
        // 智能包装机产品优势
        tit8:'智能包装机金钱豹-产品优势',
        znls5:'高精度：采用双侧真空吸附牵引系统，牵引动力足，避免送膜过程中打滑风险；运行快速平稳，提高包装精度。光电开关自动跟踪色点，准确切断；送膜边缘实时检测追踪，动态纠偏。',
        znls6:'高速度：采用脉冲式焊接封口技术，包装效率高，最高可达70包/分钟。膜卷更换简单快速，2-3 分钟即可完成操作。',
        znls7:'更前瞻：循环可持续，提前布署企业战略，；PE膜材质，原材成本优势；封口更窄，节省包材，降低成本。',
        // 液体包装机产品功能
        tit9:'液体/酱体包装机-产品功能',
        ytls1:'该包装机用于实现流体类袋装产品的自动制袋、充填、封口、打码等功能。',
        ytls2:'可兼容PA+PE复合膜8-12微米,实现500-5000g的包装覆盖范围。',
        ytls3:'餐饮类酱料；调味料；果酱；肉馅、菜馅、莲蓉、虾滑、鱼糜、豆沙、冬蓉等馅料类；以及粥、豆浆等中式餐饮流质性物料。',
        ytls4:'搭配螺杆泵、柱塞泵等计量系统，可完成对多种产品的精确定量包装。',
        // 液体包装机产品优势
        tit10:'液体/酱体包装机-产品优势',
        ytls5:'光电开关自动跟踪色点,送膜边缘实时检测追踪，动态纠偏。',
        ytls6:'丰富的袋型拓展性，根据需求选配立式背封、矩形、斜角等多种袋形模块。',
        ytls7:'智能温控，结合清料挤压功能，阻断空气混入物料，确保封口的美观和稳定，延长产品保质期。',
        ytls8:'触摸屏控制，可存储多类产品包装参数。设有多种报警功能，对照提示排查处理,维护方便。',
        ytls9:'更换对应配件实现多包装规格，灵活应对生产。',
        // 小料包装机-产品功能
        tit11:'小料包装机-产品功能',
        xlls1:'主要用于食品、日化、医药等的颗粒、液体、粉体的小料包装，如调料包，油包，袋装咖啡，冲剂药品等。',
        xlls2:'可实现物料的三边或四边封口，该机有一组纵封热辊筒装置、一组横封热辊筒装置，一组横封冷辊筒装置。封口采用辊筒方式，纵向封口花纹采用棋盘纹配合防漏线，横向封口花纹采用棋盘纹配合防漏线或平封配合防漏线。',
        xlls3:'该机还装备有方便开袋的易撕切口装置。',
         // 小料包装机-产品优势
         tit12:'小料包装机-产品优势',
         xlls4:'制袋长度在触摸屏上进行数字设定，袋长调整简单方便。',
         xlls5:'智能型光电色标定位控制系统，具有首袋自动定位功能，能够自动排除由于非正常色标图案及印刷不良的包装材料所造成干扰信号的影响，运行稳定。',
         xlls6:'实现切割切斜的在线自动调整及冷封自动调整功能，解决了传统包装机需要多次停机调整及包材浪费的问题。提高了工作效率及包装的美观。',
         xlls7:'变频电机驱动纵封机构，实现包装速度在额定范围内无级调节。',
        //开装封箱一体机-产品功能
        tit13:'开装封箱一体机-产品功能',
        kxls1:'实现袋装产品的自动叠料，开箱，装箱，封箱功能。',
        kxls2:'包装速度更高效，最高可实现60袋/分钟的堆叠需求。',
        kxls3:'包装范围广，可实现400mm*300mm*200mm—600mm*450mm*350mm(可根据要求非标定制）纸箱的产品兼容性。',
        kxls4:'整机产能实现最高12箱/分钟（取决于装箱形式）。',
        kxls5:'可一键自动实现产品规格快速切换。整体不锈钢材质，造型简约，清理简单，维修方便。',
        //开装封箱一体机-产品分类
        tit14:'开装封箱一体机-产品分类',
        kxls6:'跌落式装箱机：全自动跌落式装箱机是将整列的物料有效移载到纸箱的上方，根据装箱要求，自动整列通过定位装置保证被包装产品落入纸箱。',
        kxls7:'抓取式装箱机：全自动抓取式装箱机能自动将包装物（PET 材质瓶子、玻璃瓶、纸罐、易拉罐等）整形排列，配合纸箱自动开箱机，将抓取的物料装入打开的纸箱中。',
        kxls8:'侧推式装箱机：侧推式装箱机对产品分层分组排列组合。首先完成第一层堆叠后，堆叠机下降层，进行下一层的产品堆叠。当产品完成三层堆叠后，堆叠机上升至待装箱位置。',
        // 包装机类型
        type1:'720T大型立式全自动包装机',
        type2:'420T全自动烫边式包装机',
        type3:'430立式包装机',
        type4:'多头组合称',
        type5:'给袋式包装机',
        type6:'给袋式包装机',
        type7:'小料包装机',
        type8:'110小料包装机',
        type9:'129小料包装机',
    },
    // 异物检测
    ywjc: {
        // 产品价值
        异物检测:'异物检测',
        title1:'多科X-RAY异物检测机产品价值',
        tit1:'守护安全',
        cont1:'我们致力于让食品的生产及产品更加安全，承诺始终追求最极致的品质与表现：只提供最安全的、最好的。这是我们一切行动的原则标准，同时也驱动着我们做出所有的决定，攻克难关、勇往直前。',
        tit2:'适用于各类包装材质',
        cont2:'广泛提供各种常规包装材质的产品检测，包括塑料包装袋、纸盒 / 纸箱、铝箔袋、麻袋、流体袋、塑料托盘、PET 瓶与泡罩装等，外加产品密封性。',
        tit3:'瓶装罐装容器异物一网打尽',
        cont3:'同样适用于各种罐头食品、易拉罐饮品、玻璃瓶装罐装食品饮品和玻璃药品制剂等。不管异物是在容器底部还是在容积物之间，都无惧担忧。',
        tit4:'维护产品完美呈现',
        cont4:'在确保产品内部无异物污染的同时，减少误报，维护产品包装的完好无损，全面保障生产有效性，减少生产中因异物检测而造成的资源污染与浪费。',
        // 优异之处
        title2:'多科X-RAY异物检测机优异之处',
        tit5:'源头保障',
        cont5:'适用各种环节，从源头开始质量把控。',
        tit6:'小至0.2mm直径',
        cont6:'高灵敏度，无处可藏，更精准，更细致。',
        tit7:'多种智能算法',
        cont7:'自主研发的信号处理和图像提取技术，更稳定，更可靠。',
        tit8:'投资回报',
        cont8:'最少的产品浪费，最大的风险防范。',
        tit9:'十年品质保证',
        cont9:'科学设计搭载高品质配件，平均使用年限10年以上。',
        tit10:'物联网赋能',
        cont10:'数字化管理更轻松',
        // X-ray异物检测机 通用40系列
        title3:'X-ray异物检测机 通用40系列',
        cont11:'产品检测范围：不局限于产品类型,包装形式、包装材质，包括塑料包装,铝箔包装,玻璃罐装、金属罐装,散料产品等。',
        cont12:'异物检测范围：可检测混入产品中的金属和密度较大的非金属，如陶瓷、玻璃、石子、骨头、硬质橡胶、硬质塑料等。具体情况需依据所测产品而定。',
        // X-ray异物检测机 通用60系列
        title4:'X-ray异物检测机 通用60系列',
        cont13:'产品检测范围：不局限于产品类型,包装形式、包装材质，包括塑料包装,铝箔包装,玻璃罐装、金属罐装,散料产品等。',
        cont14:'异物检测范围：可检测混入产品中的金属和密度较大的非金属，如陶瓷、玻璃、石子、骨头、硬质橡胶、硬质塑料等。具体情况需依据所测产品而定。',
        // X-ray异物检测机 散料多通道系列
        title5:'X-ray异物检测机 散料多通道系列',
        cont15:'检测范围： 针对花生，坚果，豆类的散料物料包装前的异物检测，并选配剔除功能，将物料中混入的异物剔除。多科多通道型异物检测机分通道剔除，较少剔除的带出比。',
        // 视觉检测
        title6:'视觉检测',
        cont16:'多科视觉检测支持异形检测，比如破损、异形、偏大偏小等。支持表面异物检测，比如黑点、发丝、污点等。支持喷码漏喷、错喷、喷码模糊检测等功能。',
        // 测照检测系列
        title7:'测照检测系列',
        cont17:'该产品可检测玻璃瓶密封罐/马口铁密封罐装产品中混入的玻璃残渣、沙石、金属等异物杂质, 通过双视角检测, 确保灌装产品中无任何检测死角。',
    },
    // 金属检测
    jsjc:{
        金属检测:'金属检测',
        //产品价值
        title1:'多科金属检测机产品价值 ',
        tit1:'金属异物隐患日常守护',
        cont1:'除了因原材料中混有金属异物而造成的产品质量问题 ，也能有效减少因人员私人物件意外掉落、加工线上或附近进行的维护调试或设备故障引发的异物污染。',
        tit2:'原料，半成品，成品均可检测',
        cont2:'适用于不同生产时期中的异物混入问题，提供丰富的检测产品设备，在加工包装前或生产包装后都能及时有效地检测异物情况，从而大幅度地保护产品质量。',
        tit3:'长远减少能耗浪费',
        cont3:'包装产品在线的高速检测，提升生产效率与产品品质保证，同时提供节能、适应性强的解决方案，减少因产品性质、生产特性等制造营运所带来的能源损耗。',
        tit4:'保证检测质量一致性',
        cont4:'最大程度保障品质，减少产品召回风险，保护品牌形象与降低消费者影响',
        tit5:'十年品质保证',
        cont5:'科学设计搭载高品质配件，平均使用年限10年以上。',
        // 优异之处
        title2:'多科金属检测机优异之处',
        tit6:'先进的相位跟踪及多频切换功能',
        cont6:'产品跟踪和平衡校正功能，检测精度高 多频切换功能，针对种类较多、差异性较大的物料，能够切换不同频率检测。',
        tit7:'抗干扰能力强',
        cont7:'自主研发滤波电路，抗干扰能力强 滚筒防跑偏结构设计，防止传送带跑偏 紧凑型机身设计，输送带可实现快速拆卸。',
        tit8:'物联网赋能',
        cont8:'具有产品统计分析功能，支持数据导出到USB存储 物联网赋能，管理更轻松 优越的卫生性能，方便清洗 使用寿命长，平均寿命10年之上。',
        // 通用金检机系列
        title3:'通用金检机系列',
        cont9:'运用于非金属物料中金属异物检测，多科金属检测机检测精度高，适用范围广；使用寿命长，平均寿命10年之上。',
        // 宽幅金检机系列
        title4:'宽幅金检机系列',
        cont10:'运用于非金属物料中金属异物检测，多科金属检测机检测精度高，适用范围广；宽幅金检机多用于烘焙，粮食等物料的金属检测。',
        // 铝膜磁性金检机系列
        title5:'铝膜磁性金检机系列',
        cont11:'主要针对铝卡、铝箔包装物料的检测而设计，且该机具有结构轻盈，良好的抗干扰能力及高灵敏度的优点。',
        // 粉体管道自由落体金检机系列
        title6:'粉体管道自由落体金检机系列',
        cont12:'专门针对粉末状、颗粒状物料的在线金属异物检测需求而设计，针对粉体物料通常需要检测不锈钢筛网的断丝等高灵敏度要求，安装于物料管道输送线，可以检测出极其细小的金属杂质。',
        // 液体泵压管道金检机系列1
        title7:'液体泵压管道金检机系列1',
        cont13:'该液体泵压管道式金属检测机是专门针对果酱、馅料、酱料包、中央厨房调味包等高盐分、高含水量的流体物料检测。很好的解决了火腿肠铝扣的影响，在高温高湿环境下达到最佳的检测效果。',
        // 液体泵压管道金检机系列2
        title8:'液体泵压管道金检机系列2',
        cont14:'该金属检测机专门针对湿度极大，生产环境恶劣的粉体生产环境进行金属检测，比如鱼粉饲料等。 通过增加屏蔽罩，最大程度的避免机器核心部件受潮。',
    },
    // 重量检测
    zljc: {
        重量检测:'重量检测',
        // 产品价值
        title1:'多科重量检测机的产品价值',
        tit1:'高速度、高精度的动态称重',
        cont1:"最高可实现100米/min的动态称重，检测精度最小可实现±0.15g。",
        tit2:'风险管控',
        cont2:"最大程度保障重量，减少产品召投诉，保护品牌形象与降低消费者影响。",
        tit3:'RFID赋能',
        cont3:"称重数据可视化，检重产品可追湖。",
        // 优异之处
        title:'多科重量检测机的优异之处',
        tit4:'整体模块化设计',
        cont4:"输入、称重与输出模块化设计，方便安装与调试。 机架采用横向面采用圆管焊接，确保不积水积尘。 输入输出台面可以灵活调节台面间距，确保产品平稳过渡。",
        tit5:'台面稳定可靠,满足卫生及安全设计',
        cont5:"台面一体化设计，确保产品平稳过渡，实现稳定称重。 滚筒采用防跑偏的结构设计，多科自有进口高精度机床，确保加工精度和始终如一的可靠质量，防止传送带跑偏。 物料入口端导向板宽度可以调节，便于与生产线对接。 可选配贴标机实现称重贴标功能。 台面运动部件可接触间隙缩小，避免对人员所造成伤害。 机器明显位置张贴警示标签，确保操作人员安全。",
        tit6:'领先的高称重精度和称重稳定性',
        cont6:"采用进口的高动态响应称重传感器。 采用业内领先的高速动态滤波算法，确保动态称重的精度。 具有自动零点追踪功能，确保在温度变化、外部震动等干扰的情况下，仍然能够保持零点稳定。 支持物体传送到皮带上后停止秤重，稳定1秒后继续传送的半静态称重方式，适用于低速大间距的场景，进一步提升了称重精度。",
        tit7:'强大的软件功能',
        cont7:'操作界面简洁方便。 自定义产品学习过程，目标重量、学习次数自定义。 支持重量阈值触发模式，适合产品较薄或者容易反光产品。 支持中英文切换。 支持用户权限管理。 丰富的数据统计显示功能。        ',
        tit8:'算法优势',
        cont8:'强大的数据存储能力，可以存储数十万种配方、连续存储5年以上的生产数据记录(存储的上限，取决于硬盘的存储，标配128g存储空间，可选配128g-512g存储)。 软件可切换是否选用贴标机。 支持切换千克或克为单位显示。 支持减去皮重的称重方式。',
        // 高速度、高精度重量检测机
        title2:'高速度、高精度重量检测机',
        cont9:'适用于小包装物料的检测，检重速度更快，精度更高。',
         // 重量检测机
         title3:'重量检测机',
         cont10:'用于常规物料的检测，检重参数可调节，适用范围广，可根据客户需求定制。',
          // 重载重量检测机
        title4:'重载重量检测机',
        cont11:'常用于托盘称重或整箱称重。',
         // 金重检一体机
         title5:'金重检一体机',
         cont12:'一体式设计，满足金属检测，重量检测双重需求。',
          // 重量分级检测机
        title6:'重量分级检测机',
        cont13:'按照物料数量及重量相匹配要求，自动检重、分级和组合。',
         // 称重贴标机
         title7:'称重贴标机',
         cont14:'一体化实现物料的传送、称重和贴标功能。',
    },
    // 密封检测
    mfjc: {
        密封检测:'密封检测',
        // 优异之处
        title1:'多科密封检测机的优异之处',
        tit1:'创新多款密封检测产品',
        cont1:'国家发明专利产品，满足客户及产品的不同需求。',
        tit2:'让每个产品都有保障',
        cont2:'不抽查，连续在线检测，保障每一个产品。',
        tit3:'泄漏检测独树一帜',
        cont3:'告别传统模式，创新非浸水式、按压式袋装食品泄漏检测。',
        tit4:'精确检测针孔级泄露',
        cont4:'最高精度可达 0.3mm。',
        tit5:'强大的算法识别功能',
        cont5:'算法判别检测结果，适用多种包装袋。',
        // 在线包装密封检测机-产品功能
        title2:'在线包装密封检测机-产品功能',
        mf_fun1:'突破了传统的检测方式，智慧的HMI 的操作系统，精准的对软包装食品的气密漏气进行逐个连续的在线检测，自动识别，自动剔除。检测到的漏气软包装食品袋声光报警。',
        mf_fun2:'检测机配备了合格品通道和不合格品通道，自动分离，并从各自通道输送到指定的生产控制区域。',
        // 在线包装密封检测机-产品优势
        title3:'在线包装密封检测机-产品优势',
        mf_adv1:'该产品可根据生产工艺需求进行上、下游输送连接，多种形式的进出料设计，满足气密密封的检测要求',
        mf_adv2:'输送可达 120 袋 / 分钟（双通道）。',
        mf_adv3:'涵盖各种正压、常压、弱负压包装的软包装食品，检测范围广。',
        mf_adv4:'自适应多种产品，产品升级不影响生产线的使用，无需进行任何硬件，软件更换，降低使用成本。',
        mf_adv5:'采用最新无损定量检漏技术，解决了水浴法破坏性测试痛点。',
        // 在线包装密封密封检测机-产品功能
        title4:'在线包装密封密封检测机-产品功能',
        zx_fun1:'新一代袋装食品的泄漏检测仪，设计小巧玲珑，使用操作便捷，非浸水式、按压式袋装食品泄漏检测的方式。',
        zx_fun2:'多科泄漏检测仪不浸水，非按压的方式突破性的对袋装食品进行泄漏检测，通过精准的小样本学习进行算法处理，对袋装食品的泄漏自动进行检测。通过对大量的不同材质的袋装食品气密泄漏检测，自主形式袋装食品泄漏检测的配方，自动存储泄漏检测数据，一键管理进行多种袋装食品的泄漏检测。',
        zx_fun3:'对不同材质、不同大小尺寸、不同厚度的食品包装袋只需一台多科的便携式泄漏检测仪。',
        //在线包装密封密封检测机-产品优势
        title5:'在线包装密封密封检测机-产品优势',
        zx_adv1:'设计小巧玲珑，移动方便，操作简单',
        zx_adv2:'算法判别检测结果，适用多种包装袋。',
        zx_adv3:'配方式管理，适用多种食品包装袋，在线百分之百检测。',
        zx_adv4:'食品袋通过机器人或人工放入后，自动完成泄漏检测程序。',
        zx_adv5:'不破坏食品袋，不浸水、不用多种辅助工装模具。',
        zx_adv6:'自动灯光报警，自动记录泄漏检测数量，检测时间短。',
        zx_adv7:'关键部件采用国际、国内优质品牌，检测参数精准和稳定。',
        zx_adv8:'材质符合 GMP 要求，耐清洁、易清洗、无清洁盲区。',
        zx_adv9:'国内首创，国家发明专利产品。',
    },
     // 机器人码垛
     jqrmd: {
          机器人码垛机:'机器人码垛机',
        //   机器人码垛-产品功能
        title1:'机器人码垛-产品功能',
        cont1:'机器人码垛机用于纸箱的在线码垛，集成了栈板自动进出、物流转运及定位、机器人抓取、安全护栏等功能模块。满足客户对纸箱码垛的多种需求。同时可根据实际需求配备多工位码垛系统。',
        // 机器人码垛-产品优势
        title2:'机器人码垛-产品优势',
        jqr_adv1:'可选配多种贴标要求。',
        jqr_adv2:'通过吸盘吸附实现纸箱码垛，配负压检测，避免漏装。',
        jqr_adv3:'码垛周边配安全光栅，确保生产过程中操作人员的安全。',
        jqr_adv4:'机器人品牌采用ABB、FANUC等一线品牌（客户可指定）。',
        jqr_adv5:'配备拆盘功能及进出栈缓存工位，人工操作更加灵活。',
     },

    //  药品装备
    drug:{
        药品装备:'药品装备',
        // 立式超声波洗瓶机-产品功能
        lscs_title1:'立式超声波洗瓶机-产品功能',
        lscs_fun1:'用于冻干粉针、粉针、水针、口服液生产之前对瓶子的清洗。由进瓶系统、清洗（超声波）系统、控制系统、出瓶系统等系统构成。',
        lscs_fun2:'夹瓶机械手夹持运行并翻转瓶子过程中清洗瓶子，清洗完成后瓶子恢复成正立状态，被输出至下道工序 , 自动完成从进瓶、超声波清洗、外洗、内洗到出瓶的清洗过程，主传动采用伺服驱动，兼容大范围瓶型更换机械手实现。',
        lscs_fun3:'清洗工艺 HMI 上一键配方管理，按照国际、国内验证的清洗工艺完成包材的清洗，符合国际、国内对包材清洗的清洗效果验证要求。',
        // 立式超声波洗瓶机-产品优势
        lscs_title2:'立式超声波洗瓶机-产品优势',
        lscs_adv1:'伺服驱动主转动并控制，稳定运行长久。',
        lscs_adv2:'遵循国际、国内指南，协助设计清洗意图。',
        lscs_adv3:'人性化设计维护区域，提高维护保养效率。',
        lscs_adv4:'Q&D理念设计清洗防护区域，规避交叉污染。',
        lscs_adv5:'清洗结束后，水管路配方式管理，减少微生物滋生。',
        lscs_adv6:'自动回收注射用水作为循环水使用，降低用水成本。',
        lscs_adv7:'管路链接快捷式设计，维护更换、检修便捷、迅速。',
        lscs_adv8:'清洗工艺、清洗程序配方一键管理，杜绝人为差错。',
        lscs_adv9:'清洗压力、温度参数监控并自动记录，关键工艺参数和操作行文追溯。',
        // 热风循环灭菌隧道烘箱-产品功能
        rfxh_title1:'热风循环灭菌隧道烘箱-产品功能',
        rfxh_fun1:'模块化结构的热风循环隧道烘箱，分为预热区、高温灭菌区和冷却区三个工艺控制区域。',
        rfxh_fun2:'预热区由送风机、抽湿风机、手动调节风门、中效过滤器和高效过滤器等组成。高温灭菌区由耐高温循环风机、电加热器和高温高效过滤器等组成一个热风循环系统。',
        rfxh_fun3:'高温热空气流经高效过滤器过滤，对瓶子加热干燥和去热原；优良的灭菌去热原曲线，符合国际化要求的验证体系。',
        rfxh_fun4:'冷却区由送风机、高效过滤器、表冷器和排风机等组成一个冷却系统；冷却区有风压平衡、SIP 功能。',
        // 热风循环灭菌隧道烘箱-产品优势
        rfxh_title2:'风循环灭菌隧道烘箱-产品优势',
        rfxh_adv1:'高效过滤器负压密封设计。',
        rfxh_adv2:'满足验证要求的各种工艺接口。',
        rfxh_adv3:'关键工艺参数可追溯、可配方管理。',
        rfxh_adv4:'网带在线清洗，提供清洗验证说明。',
        rfxh_adv5:'温度控制和记录采用双通道温度传感器。',
        rfxh_adv6:'自动控制的压差平衡设计，规避压差波动风险。',
        rfxh_adv7:'三段区结构的模块化设计，空载、满载验证均匀性好。',
        rfxh_adv8:'警示灯、不同运行状态的FH值保证，HMI一键配方管理。',
        rfxh_adv9:'预定用途遵循GMP、cGMP、EUGMP、FDA、GAMP5、21CFRpart11要求。',
        // 罐装加塞机-产品功能
        gzjs_title1:'罐装加塞机-产品功能',
        gzjs_fun1:'应用于药企的研发、小试、中式及规模生产的灌装，其速度分别为50瓶/分钟、100瓶/分钟、200瓶/分钟、400瓶/分钟、500瓶/分钟等。',
        gzjs_fun2:'根据药液特性采用双泵系统、蠕动泵、柱塞泵、时间压力法等进行灌装，隔离保护有RABS、cRABS、ISOLATOR方式，根据工艺控制要求，采用顶进风、侧进风方式。',
        gzjs_fun3:'采用独特的灌装精度全程控制设计，满足灌装前残液控制、生产结束前尾液控制的要求。',
        // 罐装加塞机-产品优势
        gzjs_title2:'罐装加塞机-产品优势',
        gzjs_adv1:'应用冻干及水针灌装，灌装系统CIP/SIP。',
        gzjs_adv2:'满足不同药业特性的灌装方案，胶塞无菌转移的多种方案，提供A级监测接口及布点方案，CXO特点的灌装方案，RTU、SUS系统使用方案。',
        gzjs_adv3:'规格兼容实用性广，2-100ml规格和不同灌装量。选用高精度流量计替换传统电子秤，将全称重由间歇式运动为连续式运动，调高工作效率。',
        gzjs_adv4:'整机传动采用大模数斜齿轮，金属与尼龙齿轮搭配使用，运行稳定，噪音低。可通过快速更换部分零件实现普通压塞和真空压塞方式的切换。',

        // 轧盖机-产品功能
        ygj_title1:'轧盖机-产品功能',
        ygj_fun1:'不同规格的不同速度，配方式管理，实现上下游连线，铝盖转移设计根据“无菌轧盖”、“洁净轧盖”的要求进行布局。',
        ygj_fun2:'轧盖机设置有铝盖自净暂存台，隔离保护系统进风方式可根据车间进行设计。具有铝屑收集系统，胶塞高度、无盖识别功能。',

        // 轧盖机-产品优势
        ygj_title2:'轧盖机-产品优势',
        ygj_adv1:'兼容规格广：2-100ml。',
        ygj_adv2:'底座压力程序调整，配方式管理。',
        ygj_adv3:'采用自传加公转的方式完成轧盖。',
        ygj_adv4:'降低人力强度，自动铝盖提升装置。',
        ygj_adv5:'多种轧盖的方式，单刀、大单刀、三刀方式。',
        ygj_adv6:'前、后剔除工位，无塞和胶塞异常、无盖自动剔除。',
        // 在线灯检机-产品功能
        zxdj_title1:'在线灯检机-产品功能',
        zxdj_fun1:'检测项多：轧盖质量，冻干产品瑕疵检测，胶塞有无，损坏、胶塞上是否有药粉，瓶颈和瓶身的裂痕、划痕及西林瓶内外的污染物等。',
        zxdj_fun2:'跑瓶有内循环，可以长时间大量跑瓶。',
        zxdj_fun3:'材质符合 GMP 要求，耐清洁、易清洗、无清洁盲区。',
        zxdj_fun4:'关键部件采用国际、国内优质品牌，检测参数精准和稳定。',
        // 在线灯检机-产品优势
        zxdj_title2:'在线灯检机-产品优势',
        zxdj_adv1:'电气柜与机台集成到一起减少电缆线，降低通讯失败风险。',
        zxdj_adv2:'相机跟拍采用摆轮往返跟踪用蜗轮蜗杆结构，结构更强悍。',
        zxdj_adv3:'传动采用大模数，斜齿轮，搭配尼龙齿轮使用，噪音低，更稳定。',
        zxdj_adv4:'升降凸轮及相机倒挂式，不占桌面空间，不同规格瓶子高度可配方自动调整高度。',
    },
    /****解决方案****/
    solve: {
        topTitle:'为客户提供 高效、稳定、可视、智能的现代化工厂',
        // 智慧工厂建设与运营
        navTitle1:'智慧工厂建设与运营',
        cont1:'多科为中高端食药客户提供业务咨询，整厂规划与设计，工程建设，设备集成，物联网赋能，运营管理，技术支持与维护，核心零部件等服务。我们更贴近高速变化的市场需求，以市场需求为导向，为客户创造价值。',
        // 智能自动化装备全链路服务
        navTitle2:'智能自动化装备全链路服务',
        cont2_1:'多科可为客户提供智能包装、计量系统、整平系统、金属检测、异物检测、重量检测、密封检测、开装封箱一体机、码垛系统等后段包装自动化及智能化整体解决方案。',
        cont2_2:'客户可根据物料的特性、包装袋形式、检测要求和码垛型式来选择相匹配的包装机、检测设备、机器人码垛等组合配线。整体解决方案的优势在于它的系统性、可靠性、简单性、高效性。为客户提供交钥匙工程，有利于客户更便捷、更适用、更节约、更高效。',
        cont2_3:'还可配备多科的产线物联网系统，实现数字化驱动，发现生产痛点、打通运营、管理、生产、仓储物流等各个环节。打造数智工厂，让员工轻松办公，让领导有诗有远方。',
        更多:'更多',
        tit1_1:'智能包装机',
        cont3_1_1:"固体包装机-金钱豹：专为大尺寸PE膜包装研发设计；用于冷冻食品各类物料的包装，如速冻丸子、包子、薯条、鸡块、鸡中翅、鸡腿等；可实现0.5KG-10KG的产品兼容性。",
        cont3_1_2:"液体包装机：适用于餐饮类酱料；调味料；果酱；肉馅、菜馅、莲蓉、虾滑、鱼糜、豆沙、冬蓉等各类馅料；以及粥、豆浆等流质性物料的包装。可实 现0.5KG-5KG的包装范围。",
        cont3_1_3:"小料包装机：主要用于食品、日化、医药等的颗粒、液体、粉体的小料包装，如方便面调料包，油包等。包装范围：0.5ml-60ml或（g）。",
        tit1_2:'计量系统',
        cont3_2:'本设备与包装机配合使用。主要由机架组，差速输送组，挡边组，匹配漏洞组，感应光电组组成。通过提升机配合上料，简单高效。挡边组可更具不同的产品调节过料挡道宽度，兼容性强。产品运输时，不同输送组的速度差将产品分离，在出口处光电感应计数，稳定可靠。匹配漏洞根据每包设定数值智能匹配，智能便捷。',
        tit1_3:'整平系统',
        cont3_3:'整机采用高度集成化设计，将整平、破袋检测、剔除集于一体，实现了袋装散料的整形及破损检测。根据不同物料求，可更换模块，实现袋装产品的压平整形需求。',
        tit1_4:'金属检测机',
        cont3_4:'用于非金属物料中金属异物的检测和剔除。',
        tit1_5:'异物检测机',
        cont3_5:'将物料中的异物（石头、玻璃、金属等），缺失，破损，异形，物料计数，封边，裂缝等检测出来，并选配不同的剔除方式，将不合格的物料剔除。',
        tit1_6:'重量检测机',
        cont3_6:'实现物料高速度、高精度、高稳定性的动态称重。',
        tit1_7:'开装封箱一体机',
        cont3_7:'实现袋装产品的自动叠料装箱功能。',
        tit1_8:'机器人码垛',
        cont3_8:'机器人码垛机用于纸箱的在线码垛，集成了栈板自动进出、物流转运及定位、机器人抓取、安全护栏等功能模块。',
        // 注射剂无菌灌装联动线
        navTitle3:'注射剂无菌灌装联动线',
        cont4_1:'适用于不同注射剂类型的灌装流程:广泛适用于冻干粉针、粉针、水针、口服液等产品灌装，不管是普通药液或带有特性的药液，都能在保持药液稳定性的同时，被精准地无菌灌装。        ',
        cont4_2:'多科领航高端制造国产化:在国内顶尖团队的创新研发下，多项核心技术的突破，不仅提高了无菌灌装的效率与精度，还替代了众多进口技术及零部件，设备造价成本更低，自研自产自销的一体化模式也保障了售后运维的专业性与便捷度。',
        cont4_3:'多规格设备类型满足不同灌装场景:提供灌装速度分别为 50 瓶 / 分钟、100 瓶 / 分钟、200-400 瓶 / 分钟、500 瓶 / 分钟的无菌灌装联动线，适合研发、小试、中试，以及规模化无菌灌装的要求。',
        cont4_4:'污染隐患全面防范:人为干预是无菌生产过程中最常见的污染源，自动化灌装过程可以极大降低人工操作带来的风险。同时设备的独特设计减少了需工具更换的结构，可有效防止因异物掉落的污染风险。',
        cont4_5:'无菌灌装联动线产品包括:全自动立式超声波洗瓶机、高效热风循环灭菌隧道式烘箱、先进的灌装加塞机，以及稳定可控的轧盖机。联动线上的产品均可作为独立产品、或联动线组合一体化产品，亦或是结合多科的明星检测产品，及后道包装模块形成完整的产线解决方案。',
        tit2_1:'多项技术突破',
        cont5_1:'独特的药业罐装精度过程控制系统及首创高速称重功能。',
        tit2_2:'多功能、多场景',
        cont5_2:'灌装系统在线清洗&在线灭菌。',
        tit2_3:'自动化、智能化',
        cont5_3:'有效避免人为干预，从源头减少污染可能。',
        tit2_4:'兼容2ml-100ml',
        cont5_4:'规格兼容性光，支持多种罐装方式。',
        tit2_5:'高精度、高稳定',
        cont5_5:'伺服电机驱动，保证精度。',
        tit2_6:'应用场景',
        cont5_6:'注射类药物，口服液，冻干药品。',
    },

    /*****数智工厂*****/
    factory:{
        topTitle1_1:'数智化驱动精益化管理',
        topTitle1_2:'智能工厂云平台 + 多云多物IoT平台',
        智能制造:'智能制造',
        cont1_1:'长期围绕智能制造系统,虛拟仿真打造企业核心技术,致力于发掘智能制造系统。',
        智慧能源:'智慧能源',
        cont1_2:'智能维护精准分析,从多角度提升能效，优化设备在其生命周期中的性能,降低成本并减少碳足迹。',
        智慧资产:'智慧资产',
        cont1_3:'转化为市场价值的知识，是企业所有能够带来利润的知识和技能。智慧资本的价值，就是人的智慧所在。',
        场景方案:'场景方案',
        cont1_4:'可用于用户的设计，里程碑,用户应用,可实现了用户与生活的环境的收集,与用户访问方式。',
        // 智能制造
        // 设备物联网平台
        设备物联网平台:'设备物联网平台',
        cont2_1:'智能硬件管理，实现对设备的远程监控和控制。',
        cont2_2:'设备数据看板，提供更全面的数据支持。',
        cont2_3:'设备能耗管理，对设备的能耗实时监测和管理。',
        cont2_4:'故障预警处理，对设备的实时监测和数据分析。',
        cont2_5:'设备台账，对设备进行全面的管理和记录。',
        // 仓储管理系统(WMS)
        仓储管理系统:'仓储管理系统',
        cont3_1:'库存管理，实时跟踪和管理库存。',
        cont3_2:'入库管理，记录和管理所有入库的产品。',
        cont3_3:'出库管理，管理和跟踪所有出库的产品。',
        cont3_4:'仓库布局优化，帮助企业优化仓库布局。',
        cont3_5:'采购管理，管理和跟踪所有采购活动。',
        cont3_6:'物流管理，管理和跟踪所有物流运输活动。',
        cont3_7:'数据分析，分析和报告仓库运作的关键指标。',
        // 制造运营管理(MES)
        制造运营管理:'制造运营管理',
        cont4_1:'生产监控，可视方式呈现捕获的数据。',
        cont4_2:'质量管理，响应生产条件并推动持续改进。',
        cont4_3:'警报和通知，解决最大限度地减少停机时间。',
        cont4_4:'高级分析，数据可视化将数据变为现实。',
        cont4_5:'维护管理，提高设备可靠性并延长使用寿命',
        // 高级计划排程(APS)
        高级计划排程:'高级计划排程',
        cont5_1:'订单交期预测，预测订单的交货日期。',
        cont5_2:'主计划排程，制定出主生产计划。',
        cont5_3:'详细工序计划，细化每个工序的生产计划。',
        cont5_4:'物料需求计划，预测所需的原材料和零部件数量。',
        cont5_5:'产能负荷分析，评估各个工序的生产能力。',
        cont5_6:'计划可视化，图形化界面显示生产计划和进度。',
        // 智慧能源
        // 能源管理(EMS)
        能源管理:'能源管理',
        cont7_1:'能源计划，制定企业的能源消费计划任务。',
        cont7_2:'能源监控，实时监控企业的能源消耗情况。',
        cont7_3:'能源统计，能源成本比重，发展趋势准确掌握。',
        cont7_4:'能源消费分析，对能源消耗情况进行分析和评估。',
        cont7_5:'重点能耗设备管理，对重点能耗设备进行管理。',
        // 智慧资产
        // 设备数字运维
        设备数字运维:'设备数字运维',
        cont8_1:'统一管理，自动记录和保存设备和运维日志。',
        cont8_2:'智能化操作和维护，完成智能化、数字化呈现。',
        cont8_3:'实时采集设备运行参数，定位影响效率的环节。',
        cont8_4:'监控设备运行状态，以便及时发现和解决问题。',
        cont8_5:'对设备进行故障预警，提前预测可能出现的故障。',
        // 设备效率助手
        设备效率助手:'设备效率助手',
        cont9_1:'设备监控，实时监控设备的运行状态。',
        cont9_2:'故障预警，防止设备长时间停机导致的生产延误。',
        cont9_3:'数据分析，为设备的优化和改进提供依据。',
        cont9_4:'远程控制，提高设备的使用效率和便捷性。',
        cont9_5:'设备更新，确保设备始终处于最佳的工作状态。',
        // 设备健康(PHM)
        设备健康:'设备健康',
        cont10_1:'故障检测，发现设备异常立即发出警报。',
        cont10_2:'故障隔离，保证设备的正常运行。',
        cont10_3:'故障诊断，找出故障原因进行针对性修复。',
        cont10_4:'健康管理，延长设备的使用寿命。',
        // 企业资产管理(EAM)
        企业资产管理:'企业资产管理',
        cont11_1:'资产注册和识别，准确记录和识别其所有资产。',
        cont11_2:'预防性维护，可以设置预防性维护计划。',
        cont11_3:'故障处理，跟踪和记录设备的故障情况。',
        cont11_4:'库存管理，有效地管理备件和库存。',
        cont11_5:'采购管理，确保企业拥有所需的资源和物资。',
        // 场景方案
        生产场景解决方案:'生产场景解决方案',
        content1:'提高生产效率和产品质量对于企业的发展至关重要，而优化生产流程、提高人员素质和引进高端技术则是实现这一目标的有效途径。因此，企业应把握机会，从多方面入手，逐步改善生产现场的各个环节，以实现企业的稳步增长和发展。',
        智慧园区场景解决方案:'智慧园区场景解决方案',
        content2:'园区规划建设越来越注重各种基础配套设施，以更好地服务促进高新产业的发展。尤其是注重产业园区的信息化建设，构建互联互通、资源共享的信息资源网络，以信息化带动产业化是加速产业园区发展的重要内容。',
        数字化服务运营场景:'数字化服务运营场景',
        content3:'利用信息技术手段和数字化工具来实现企业运营活动的方式，主要从数字化市场营销、数字化供应链管理、数字化客户关系管理、数字化生产管理等四个方面解决数字化服务运营场景方案。',
    },

    /*** 协同创新 ***/
    service:{
        topTitle:'协同创新推动行业发展的引擎',
        客制化研发中心:'客制化研发中心',
        产学研合作:'产学研合作',
        高质量发展:'高质量发展',
    },
// 
    user: {
        name:'English',
        login:'登录',
        register:'注册',
        loginUsername:'请输入邮箱/手机号',
    }
}
